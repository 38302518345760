import ALink from "../components/common/ALink";
import {connect} from "react-redux";
import {Navigate} from "react-router-dom";
import {useEffect} from "react";
import LoginForm from "../forms/loginForm";
import useTranslation from "../utils/hooks/useTranslation";


function Login({user}) {
    const {t} = useTranslation();
    useEffect(() => {
        let offset = document.querySelector('body').getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout(() => {
            window.scrollTo({top: offset, behavior: 'smooth'});
        }, 50);

    }, [])

    if (user) {
        return (<Navigate to="/" replace/>)
    }

    return (
        <main className="main">
            <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><ALink href="/">{t("label.home")}</ALink></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t("message.my_account")}
                                </li>
                            </ol>
                        </div>
                    </nav>

                    <h1>{t("message.my_account")}</h1>
                </div>
            </div>


            <div className="container login-container">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="row justify-content-center">
                            <div>
                                <div className="heading mb-1">
                                    <h2 className="title">{t("label.login")}</h2>
                                </div>

                                <LoginForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    }
}

export default connect(mapStateToProps, null)(Login);