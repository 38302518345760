import React from 'react';
import Reveal from 'react-awesome-reveal';


// Import Apollo Server and Query
// import withApollo from '../../../../server/apollo';
// import { GET_SPECIAL_PRODUCTS } from '../../../../server/queries';

// Import Custom Component
import ProductThree from '../../../features/products/product-three';

// Import Keyframes
import { fadeInLeftShorter } from '../../../../utils/data/keyframes'
import useTranslation from "../../../../utils/hooks/useTranslation";
import {connect} from "react-redux";

function ProductWidgetContainer ( props ) {
    const { t } = useTranslation();
    const { adClass = "", featuredProducts, latestProducts } = props;
    const loading = false;

    return (
        <section className={ `product-widgets-container pb-2 skeleton-body skel-shop-products ${loading ? '' : 'loaded'} ${adClass}` }>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6 pb-5 pb-lg-0">
                        {
                            loading ?
                                [ 0, 1, 2 ].map( ( item, index ) =>
                                    <div className="skel-product-col skel-pro mb-2" key={ "ProductThree" + index }></div>
                                )
                                :

                                <>
                                    <h4 className="section-sub-title">{t("message.featured_products")}</h4>
                                    {
                                        featuredProducts.list?.slice( 0, 3 ).map( ( product, index ) => (
                                            <ProductThree product={ product } key={ `ProductThree` + index } />
                                        ) )
                                    }
                                </>

                        }
                    </div>

                    {/*<div className="col-lg-3 col-sm-6 pb-5 pb-lg-0">*/}
                    {/*    {*/}
                    {/*        loading ?*/}
                    {/*            [ 0, 1, 2 ].map( ( item, index ) =>*/}
                    {/*                <div className="skel-product-col skel-pro mb-2" key={ "ProductThree" + index }></div>*/}
                    {/*            )*/}
                    {/*            :*/}

                    {/*            <>*/}
                    {/*                <h4 className="section-sub-title">{t("message.best_selling_products")}</h4>*/}
                    {/*                {*/}
                    {/*                    bestSelling.slice( 0, 3 ).map( ( product, index ) => (*/}
                    {/*                        <ProductThree product={ product } key={ `ProductThree` + index } />*/}
                    {/*                    ) )*/}
                    {/*                }*/}
                    {/*            </>*/}

                    {/*    }*/}
                    {/*</div>*/}

                    <div className="col-lg-6 col-sm-6 pb-5 pb-sm-0">
                        {
                            loading ?
                                [ 0, 1, 2 ].map( ( item, index ) =>
                                    <div className="skel-product-col skel-pro mb-2" key={ "ProductThree" + index }></div>
                                )
                                :

                                <>
                                    <h4 className="section-sub-title">{t("message.latest_products")}</h4>

                                    {
                                        latestProducts.list?.slice( 0, 3 ).map( ( product, index ) => (
                                            <ProductThree product={ product } key={ `ProductThree` + index } />
                                        ) )
                                    }
                                </>
                        }
                    </div>

                    {/*<div className="col-lg-3 col-sm-6 pb-0">*/}
                    {/*    {*/}
                    {/*        loading ?*/}
                    {/*            [ 0, 1, 2 ].map( ( item, index ) =>*/}
                    {/*                <div className="skel-product-col skel-pro mb-2" key={ "ProductThree" + index }></div>*/}
                    {/*            )*/}
                    {/*            :*/}

                    {/*            <>*/}
                    {/*                <h4 className="section-sub-title">{t("message.top_rated_products")}</h4>*/}

                    {/*                {*/}
                    {/*                    topRated.slice( 0, 3 ).map( ( product, index ) => (*/}
                    {/*                        <ProductThree product={ product } key={ `ProductThree` + index } />*/}
                    {/*                    ) )*/}
                    {/*                }*/}
                    {/*            </>*/}
                    {/*    }*/}
                    {/*</div>*/}
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = ( state ) => {
    return {
        featuredProducts: state.products.featured,
        latestProducts: state.products.latest,
    }
}

const mapDispatchToProps = {}

export default connect( mapStateToProps, mapDispatchToProps )( ProductWidgetContainer );