import {
    ADD_PRODUCT_TO_CART, CHANGE_PRODUCT_QUANTITY, GET_CART,
    REMOVE_PRODUCT_FROM_CART
} from "../action_types";
import {toast} from "react-toastify";
import CartPopup from "../../components/features/modals/add-to-cart-popup";

const initialState = {
    items: [],
    loading: false,
};

const cart = (state = initialState, action) => {
    let items;
    let itemIndex;

    switch (action.type) {
        case GET_CART:
            state = {
                ...state,
                loading: true
            };

            break;
        case GET_CART.concat("_SUCCESS"):
            items = action.payload || [];

            state = {
                ...state,
                loading: false,
                items: items,
            };

            break;
        case GET_CART.concat("_FAILURE"):
            state = {
                ...state,
                loading: false,
            };

            break;
        case ADD_PRODUCT_TO_CART:
            items = [...state.items];
            itemIndex = items.findIndex((item => item.id === action.payload.id));

            if(itemIndex > -1) {
                if(items[itemIndex].maxQuantity >= (items[itemIndex].quantity + 1)) {
                    items[itemIndex].quantity++
                }
            } else {
                items.push(action.payload);
                toast( <CartPopup product={ { ...action.payload } } /> );
            }

            state = {
                ...state,
                items: items
            };

            break;
        case REMOVE_PRODUCT_FROM_CART:
            items = [...state.items];
            items = items.filter((item => item.id !== action.payload));

            state = {
                ...state,
                items: items
            };

            break;
        case CHANGE_PRODUCT_QUANTITY:
            items = state.items.map( ( item, index ) => {
                return index === action.payload.id ? { ...item, quantity: action.payload.quantity } : item
            } )

            state = {
                ...state,
                items: items
            };

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default cart;