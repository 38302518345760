import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from "../../common/ALink";

// Import Utils
import useTranslation from "../../../utils/hooks/useTranslation";

function BlogTypeOne ( props ) {
    const { t } = useTranslation();
    const { adClass = '', blog } = props;
    let date = new Date( blog.updated_at );
    let monthArray = [ t("label.jan"), t("label.feb"), t("label.mar"),
        t("label.apr"), t("label.may"), t("label.jun"),
        t("label.jul"), t("label.aug"), t("label.sep"),
        t("label.oct"), t("label.nov"), t("label.dev") ];

    function openModal ( e ) {
        e.preventDefault();
        props.showVideo();
    }

    return (
        <article className={ `post media-with-lazy ${adClass}` }>
            <figure className="post-media zoom-effect">
                <ALink href={ `/blog/${blog.id}` }>
                    <div className="lazy-overlay"></div>

                    <LazyLoadImage
                        alt="post_image"
                        src={ process.env.REACT_APP_PUBLIC_ASSET_URI + blog.image.url }
                        threshold={ 500 }
                        width="100%"
                        height="auto"
                        effect="blur"
                    />
                </ALink>

                {
                    blog.video &&
                    <a className="btn-play btn-iframe" onClick={ openModal } href="https://www.youtube.com/watch?v=vBPgmASQ1A0"><i className="fas fa-play"></i></a>
                }

                <div className="post-date">
                    <span className="day">{ `${date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()}` }</span>
                    <span className="month">{ monthArray[ date.getUTCMonth() ] }</span>
                </div>
            </figure>

            <div className="post-body">
                <h2 className="post-title">
                    <ALink href={ `/blog/${blog.id}` }>{ blog.title }</ALink>
                </h2>

                <div className="post-content">
                    <p>{ blog.short_description }</p>
                </div>
            </div>
        </article >
    )
}

export default BlogTypeOne;