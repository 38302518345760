import {
    GET_SLIDER_DATA,
} from "../action_types";

const initialState = {
    data: null,
    loading: false,
    error: false,
};

const slider = (state = initialState, action) => {
    switch (action.type) {
        case GET_SLIDER_DATA:
            state = {
                ...state,
                loading: true,
            };

            break;
        case GET_SLIDER_DATA.concat("_SUCCESS"):
            state = {
                ...state,
                loading: false,
                data: action.payload
            };

            break;
        case GET_SLIDER_DATA.concat("_FAILURE"):
            state = {
                ...state,
                loading: false,
                error: true,
            };

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default slider;