export const partners = {
    "list": [
        {
            "id": 8,
            "name": "Dahua",
            "image": "images/partners/dahua.jpg",
            "url": "https://www.dahuasecurity.com/"
        },
        {
            "id": 6,
            "name": "Roger",
            "image": "images/partners/roger.jpg",
            "url": "https://www.rogertechnology.it/en/"
        },
        {
            "id": 1,
            "name": "Teletek",
            "image": "images/partners/teletek.png",
            "url": "https://www.teletek-electronics.com/"
        },
        {
            "id": 3,
            "name": "Karel",
            "image": "images/partners/karel.jpg",
            "url": "https://www.karel-electronics.com/"
        },
        {
            "id": 2,
            "name": "ZKTeco",
            "image": "images/partners/ZKTeco.png",
            "url": "https://zkteco.eu/"
        },
        {
            "id": 4,
            "name": "Zeybek Elektrik",
            "image": "images/partners/Zeybek.png",
            "url": "http://www.zeybekgrup.com/"
        },
        {
            "id": 5,
            "name": "Aypro Smart Systems",
            "image": "images/partners/Aypro.png",
            "url": "https://www.aypro.com/"
        },
        {
            "id": 7,
            "name": "Ajax Systems",
            "image": "images/partners/Ajax-Systems.png",
            "url": "https://ajax.systems/"
        },
        {
            "id": 9,
            "name": "Cambox",
            "image": "images/partners/Cambox.jpg",
            "url": "http://www.cambox.com.tr/"
        },
        {
            "id": 10,
            "name": "Ekingen Audio Systems",
            "image": "images/partners/Ekingen.png",
            "url": "https://www.ekingen.com.tr/tr/urunler/ses-sistemleri/"
        },
        {
            "id": 11,
            "name": "Aco Technology",
            "image": "images/partners/Aco.png",
            "url": "https://www.aco.com.pl/"
        },
        {
            "id": 12,
            "name": "Dorcas",
            "image": "images/partners/Dorcas.png",
            "url": "https://www.dorcas.com/?lang=en"
        }
    ],
}