import { useParams } from 'react-router-dom';

// Import Custom Component
import BlogSingle from '../../components/features/blogs/blog-single';

//Import Settings
import {useEffect} from "react";
import {getBlogDetails} from "../../store/blog/actions";
import {connect} from "react-redux";

function Single ({getBlogDetails, blog}) {
    const params = useParams();

    useEffect(() => {
        getBlogDetails(params.id);
    },[])

    const {loading, data} = blog;

    return (
        <div className="col-lg-9">
            {data && <BlogSingle blog={ data } loading={ loading } />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        blog: state.blogs.selected_blog,
    }
}

const mapDispatchToProps = {
    getBlogDetails,
}

export default connect( mapStateToProps, mapDispatchToProps )( Single );