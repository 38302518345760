import { useLocalStorage } from "./useStorage"
import { useSelector } from 'react-redux';

function usePrice() {
    const currencies = useSelector((state) => state.currency);
    const {list} = currencies;
    const [currency, setCurrency] = useLocalStorage("currency", "LEK")
    const [fallbackCurrency, setFallbackCurrency] = useLocalStorage(
        "fallbackCurrency",
        "LEK"
    )

    const convert = price => {

        return (
            getConvertedPrice(currency, price) ??
            getCurrencySymbol(currency) + " " + price.toFixed(2)
        )
    }

    const getCurrencySymbol = (currency) => {
        switch (currency) {
            case "EUR":
                return "€";
            default:
                return currency;
        }
    }

    const getConvertedPrice = (currency, price) => {
        let currencyData = list?.filter((curr) => curr.name?.toLowerCase() === currency.toLowerCase())[0];

        if(currencyData) {
            const newPrice = currencyData.converted_value * price;

            return getCurrencySymbol(currency) + " " + newPrice.toFixed(2);
        }
    }

    return {
        currency,
        setCurrency,
        fallbackCurrency,
        setFallbackCurrency,
        c: convert,
    }
}

export default usePrice;