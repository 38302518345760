import React from 'react';
import ALink from "./ALink";
import useTranslation from "../../utils/hooks/useTranslation";
import {connect} from "react-redux";

function Footer({categories, partners}) {
    const {t} = useTranslation();
    const getYear = () => {
        const date = new Date();
        return date.getFullYear();
    }

    return (
        <footer className="footer bg-dark">
            <div className="footer-middle">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 widget-list">
                            <div className="widget">
                                <h4 className="widget-title">{t("message.contact_info")}</h4>
                                <ul className="contact-info">
                                    <li>
                                        <span className="contact-info-label">{t("label.address")}:</span>Rosenaustrasse
                                        1, 6032 Emmen – Switzerland
                                    </li>
                                    <li>
                                        <span className="contact-info-label">{t("label.phone")}:</span><ALink
                                        href="tel:">(+41) 79 803 55 55</ALink>
                                    </li>
                                    <li>
                                        <span className="contact-info-label">{t("label.email")}:</span>
                                        <ALink href="mailto:info@netzwerkdua.ch">info@netzwerkdua.ch</ALink>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <span className="contact-info-label">{t("message.working_days_hours")}:</span>*/}
                                    {/*    {t("message.open_hours")}*/}
                                    {/*</li>*/}
                                </ul>
                                <div className="social-icons">
                                    <a href="https://www.facebook.com/NetzwerkDua" target={"_blank"}
                                       rel={"noopener noreferrer"}
                                       className="social-icon social-facebook icon-facebook"
                                       title="Facebook"></a>
                                    <a href="https://www.instagram.com/netzwerkdua/" target={"_blank"}
                                       rel={"noopener noreferrer"}
                                       className="social-icon social-instagram icon-instagram"
                                       title="Instagram"></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 widget-list">
                            <div className="widget">
                                <h4 className="widget-title">{t("label.products")}</h4>

                                <ul className="links">
                                    {categories.list?.map((category) =>
                                        <li key={"category-" + category.id}><ALink
                                            href={`/shop?category=${category.id}`}>{category.name}</ALink></li>
                                    )}
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 widget-list">
                            <div className="widget">
                                <h4 className="widget-title">{t("label.brands")}</h4>

                                <ul className={"links"}>
                                    {partners.list?.map((partner) => (
                                        <li key={"partner-" + partner.id}><a target={"_blank"}
                                                                             rel={"noopener noreferrer"}
                                                                             href={partner.url}>{partner.name}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="footer-bottom">
                    <div className="container d-sm-flex align-items-center">
                        <div className="footer-left">
                            <span
                                className="footer-copyright">Dua Netzwerk - {getYear()}. {t("message.all_rights_reserved")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

const mapStateToProps = (state) => {
    return {
        categories: state.categories,
        partners: state.partners
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Footer));