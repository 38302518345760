import React, {useEffect} from 'react';
import {updateSearchParams} from "../../utils";
import {useSearchParams} from "react-router-dom";

function Pagination({ page, totalPage, incrementPage, decrementPage, setProductPage }) {
    let indexsToShow = [];
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        onPageChange(page);
    }, [page])

    function onPageChange(page) {
        if(page) {
            let params = {
                page: page,
            };

            params = updateSearchParams(searchParams, params);
            setSearchParams(params);
        }
    }

    for (let i = 0; i < Math.min(totalPage, 3); i++) {
        if (page < 4 || page > totalPage - 3) {
            if (page < 4) {
                indexsToShow[i] = i + 1;
            }

            if (totalPage > 4 && page > totalPage - 3) {
                indexsToShow[i] = totalPage - 2 + i;
            }
        } else {
            indexsToShow[i] = page - 1 + i;
        }
    }

    return (
        <>
            {totalPage > 1 &&
                <ul className="pagination toolbox-item">
                    {
                        page > 1 &&
                        <li className="page-item" onClick={() => decrementPage()}>
                            <div className="page-link page-link-btn"><i className="icon-angle-left"></i></div>
                        </li>
                    }

                    {
                        indexsToShow.map(item => (
                            <li className={`page-item ${Number(page) === item ? 'active' : ''}`} key={`page-${item}`} onClick={() => setProductPage(item)}>
                                <div className="page-link">
                                    {item}{Number(page) === item && <span className="sr-only">(current)</span>}
                                </div>
                            </li>

                        ))
                    }

                    {
                        page < totalPage &&
                        <li className="page-item" onClick={() => incrementPage()}>
                            <div className="page-link page-link-btn"><i className="icon-angle-right"></i></div>
                        </li>
                    }
                </ul>
            }
        </>
    )
}

export default React.memo(Pagination);