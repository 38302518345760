import React, {useEffect, useMemo, useState} from 'react';
import SlideToggle from 'react-slide-toggle';
import StickyBox from 'react-sticky-box';
import Tree from 'rc-tree';

import {getProductList, setCategoryFilter} from "../../../../store/products/actions";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";
import useTranslation from "../../../../utils/hooks/useTranslation";

const TreeNode = ( props ) => {
    return (
        <>
            { props.name }
            { props.count && <span className="products-count">({props.count})</span> }
        </>
    )
}

function ProductSidebarOne ( props ) {
    const {t} = useTranslation();
    const { adClass = "", category } = props;
    const navigate = useNavigate();
    const {list, loading} = category;
    const [expandedKeys, setExpandedKeys] = useState([]);

    const categories = useMemo( () => {
        let cats = list ? list : [];
        let stack = [],
            result = [];
        result = cats.reduce( ( acc, cur ) => {
            let newNode = {
                key: cur.id,
                title: <TreeNode name={ cur.name } count={ cur.children.length ? cur.children.length : null } />,
                children: fixChildrenFormat(cur.children)
            };

            acc.push( newNode );
            stack.push( {
                name: cur.name,
                children: newNode.children
            } );

            return acc;
        }, [] );

        while ( stack.length ) {
            let temp, children, childNode;
            temp = stack[ stack.length - 1 ];
            stack.pop();
            children = cats.filter( item => item.parent === temp.name );
            children.forEach( child => {
                childNode = {
                    key: child.id,
                    title: <TreeNode name={ child.name } count={ child.count } />,
                    children: []
                };
                temp.children.push( childNode );
                stack.push( {
                    name: child.name,
                    children: childNode.children
                } );
            } );
        }

        return result;
    }, [ list ] );

    useEffect( () => {
        return () => {
            closeSidebar();
        }
    }, [] )

    useEffect(() => {
        let treeNodes = document.querySelectorAll('.rc-tree-treenode');
        let isChildren = false;
        treeNodes = [...treeNodes].filter((node, index) => {
            if(node.classList.contains('rc-tree-treenode-switcher-open')) {
                isChildren = true;
            }

            if(isChildren) {
                node.classList.add("border-0");
            }

            if(node.classList.contains('rc-tree-treenode-leaf-last') && index < (treeNodes.length - 1)) {
                isChildren = false;
            }

            return node.classList.contains('rc-tree-treenode-switcher-close')
        })
    }, [expandedKeys])

    function fixChildrenFormat(children) {
        let childNodes = [];
        children?.forEach((child) => {
            childNodes.push({
                key: child.id,
                title: <TreeNode name={ child.name } count={ child.children.length ? child.children.length : null } />,
                children: fixChildrenFormat(child.children)
            });
        })

        return childNodes;
    }

    function sidebarToggle ( e ) {
        let body = document.querySelector( 'body' );

        e.preventDefault();
        if ( body.classList.contains( 'sidebar-opened' ) ) {
            body.classList.remove( 'sidebar-opened' );

        } else {
            body.classList.add( 'sidebar-opened' );
        }
    }

    function filterByCategory ( selected ) {
        navigate("/shop?category=" + selected[0]);
    }

    function closeSidebar () {
        document.querySelector( 'body' ).classList.contains( 'sidebar-opened' ) && document.querySelector( 'body' ).classList.remove( 'sidebar-opened' );
    }

    function toggleCategory( categoryId ) {
        expandedKeys.includes(categoryId) ? setExpandedKeys(expandedKeys.filter(i => i !== categoryId)) : setExpandedKeys([ ...expandedKeys, categoryId ]);
    }

    return (
        <>
            <div className="sidebar-overlay" onClick={ closeSidebar }></div>
            <div className="sidebar-toggle custom-sidebar-toggle" onClick={ e => sidebarToggle( e ) }><i className="fas fa-sliders-h"></i></div>
            <aside className={ `sidebar-product col-lg-3 mobile-sidebar skeleton-body skel-shop-products ${ adClass } ${ loading ? '' : 'loaded' }` }>
                <StickyBox className="sticky-wrapper" offsetTop={ 70 }>
                    {
                        loading ?
                            <div className="skel-widget"></div>
                            :
                            <div className="widget widget-product-categories mb-3">
                                <SlideToggle>
                                    { ( { setCollapsibleElement } ) => (
                                        <>
                                            <h3 className="widget-title my-4">
                                                <a>{t("label.categories")}</a>
                                            </h3>
                                            <div className="overflow-hidden" ref={ setCollapsibleElement }>
                                                <div className="widget-body">
                                                    <Tree
                                                        className="no-icon cat-list border-0"
                                                        selectable={ true }
                                                        showIcon={ false }
                                                        switcherIcon={ ( props ) => {
                                                            return ( !props.isLeaf ?
                                                                <span className="toggle"  onClick={() => toggleCategory(props.eventKey)}></span>
                                                                : ''
                                                            )
                                                        } }
                                                        treeData={ categories }
                                                        onSelect={ filterByCategory }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) }
                                </SlideToggle>
                            </div>
                    }
                </StickyBox>
            </aside>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        category: state.categories,
        selectedCategory: state.products.selected_category,
    }
}

const mapDispatchToProps = {setCategoryFilter, getProductList}

export default connect( mapStateToProps, mapDispatchToProps )( ProductSidebarOne );