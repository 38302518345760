import {GET_CATEGORY_LIST} from "../action_types";

const initialState = {
    loading: false,
    list: [],
    error: false,
};

const categories = (state = initialState, action) => {
    let list;

    switch (action.type) {
        case GET_CATEGORY_LIST:
            state = {
                ...state,
                loading: true
            };

            break;
        case GET_CATEGORY_LIST.concat("_SUCCESS"):
            list = state.list ? [...state.list] : [];
            list = [...action.payload];

            state = {
                ...state,
                list: list,
                loading: false,
            };

            break;
        case GET_CATEGORY_LIST.concat("_FAILURE"):
            state = {
                ...state,
                loading: false,
                error: true,
            };

            break;

        default:
            state = {...state};
            break;
    }

    return state;
};

export default categories;