import React from 'react';
import ALink from '../../../common/ALink';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PDFIcon from "../../../../assets/images/icons/PDF_icon.svg";
import useTranslation from "../../../../utils/hooks/useTranslation";

export default function SingleTabOne ( props ) {
    const { t } = useTranslation();
    const { adClass = "", product } = props;

    return (
        <>
            <div className="skel-pro-tabs"></div>
            {
                product &&
                <Tabs className={ `product-single-tabs ${adClass}` } selectedTabClassName="active" selectedTabPanelClassName="show">
                    {product.documents.length > 0 &&
                        (<TabList className="nav nav-tabs">
                            <Tab className="nav-item">
                                <ALink href="#" className="nav-link">{t("label.documents")}</ALink>
                            </Tab>
                        </TabList>)
                    }

                    {product.documents.length > 0 &&
                        (<TabPanel className="tab-pane fade">
                            <div className="product-size-content">
                                <div className="row">
                                    <div className="col-md-4 d-flex align-items-center justify-content-center">
                                        <img src={PDFIcon} alt="body shape" className={"m-0"} width="50" height="150"/>
                                    </div>

                                    <div className="col-md-8">
                                        <table className="table table-size">
                                            <thead>
                                            <tr>
                                                <th>{t("label.name")}</th>
                                                <th className={"text-nowrap"}>{t("label.link")}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {product.documents?.map((document, key) =>
                                                (
                                                    <tr key={key}>
                                                        <td>{document.name}</td>
                                                        <td>
                                                            <a href={process.env.REACT_APP_PUBLIC_ASSET_URI + document.url}
                                                               target={"_blank"} rel={"noopener noreferrer"}>
                                                                Link <i className="sicon-link"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>)
                    }
                </Tabs>
            }
        </>
    );
}

