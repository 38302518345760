import {
    DECREMENT_PRODUCT_PAGE,
    GET_FEATURED_PRODUCT_LIST,
    GET_LATEST_PRODUCT_LIST,
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_LIST,
    GET_SIMILAR_PRODUCTS,
    INCREMENT_PRODUCT_PAGE,
    SET_CATEGORY_FILTER,
    SET_PRODUCT_LIMIT,
    SET_PRODUCT_PAGE,
    SET_PRODUCT_SEARCH_FILTER
} from "../action_types";

export const getFeaturedProductList = (params) => {
    return {
        type: GET_FEATURED_PRODUCT_LIST,
        api: true,
        payload: params
    }
};

export const getProductList = (params) => {
    return {
        type: GET_PRODUCT_LIST,
        api: true,
        payload: params
    }
}

export const getLatestProductList = () => {
    return {
        type: GET_LATEST_PRODUCT_LIST,
        api: true,
    }
}

export const getProductDetails = (id) => {
    return {
        type: GET_PRODUCT_DETAILS,
        api: true,
        payload: id,
    }
}

export const setProductPage = (page) => {
    return {
        type: SET_PRODUCT_PAGE,
        payload: page
    }
}

export const incrementProductPage = () => {
    return {
        type: INCREMENT_PRODUCT_PAGE,
    }
}

export const decrementProductPage = () => {
    return {
        type: DECREMENT_PRODUCT_PAGE,
    }
}

export const setProductLimit = (limit) => {
    return {
        type: SET_PRODUCT_LIMIT,
        payload: limit
    }
}

export const setCategoryFilter = (categoryId) => {
    return {
        type: SET_CATEGORY_FILTER,
        payload: categoryId
    }
}

export const setProductSearchFilter = (search) => {
    return {
        type: SET_PRODUCT_SEARCH_FILTER,
        payload: search
    }
}

export const getSimilarProducts = (id) => {
    return {
        type: GET_SIMILAR_PRODUCTS,
        api: true,
        payload: id,
    }
}