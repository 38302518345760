import useForm from "../utils/hooks/useForm";
import {connect} from "react-redux";
import useTranslation from "../utils/hooks/useTranslation";
import {getBusinessRegisterToken} from "../store/forms/actions";

function BusinessRegistrationForm({getBusinessRegisterToken}) {
    const { t } = useTranslation();
    const register = () => {
        if(!hasErrors()) {
            getBusinessRegisterToken(values);
        }
    }

    const registerForm = {
        full_name: '',
        company: '',
        email: '',
        phone: '',
        city: '',
        nipt: '',
    };

    const [values, handleChange, handleSubmit, errors] = useForm(registerForm, register);

    const hasErrors = () => {
        let hasErrors = false;
        Object.values(values).some((value) => {
            if(!value || value === '') {
                hasErrors = true;
                return true;
            }

            return false;
        });

        return hasErrors;
    }

    return (
        <>
            <form>
                <div className="position-relative">
                    <label htmlFor="register-company">
                        {t("label.company")} <span className="required">*</span>
                    </label>
                    <input type="text" name="company" className="form-input form-wide" id="register-company"
                           onChange={handleChange}
                           value={values.company}
                           autoComplete="off"
                           placeholder={t("label.company")}
                           required/>
                    {errors.company && (
                        <span className="invalid-feedback relative-error">
                            {t("message.please_provide_a_valid_company_name")}.
                        </span>
                    )}
                </div>
                <div className="position-relative">
                    <label htmlFor="register-vat">
                        {t("label.vat")} <span className="required">*</span>
                    </label>
                    <input type="text" name="nipt" className="form-input form-wide" id="register-vat"
                           onChange={handleChange}
                           value={values.nipt}
                           autoComplete="off"
                           placeholder={t("label.vat")}
                           required/>
                    {errors.nipt && (
                        <span className="invalid-feedback relative-error">
                            {t("message.please_provide_a_valid_vat")}.
                        </span>
                    )}
                </div>
                <div className="position-relative">
                    <label htmlFor="register-full-name">
                        {t("label.full_name")} <span className="required">*</span>
                    </label>
                    <input type="text" name="full_name" className="form-input form-wide" id="register-full-name"
                           onChange={handleChange}
                           value={values.full_name}
                           autoComplete="off"
                           placeholder={t("label.full_name")}
                           required/>
                    {errors.full_name && (
                        <span className="invalid-feedback relative-error">
                            {t("message.please_provide_a_valid_full_name")}.
                        </span>
                    )}
                </div>
                <div className="position-relative">
                    <label htmlFor="register-city">
                        {t("label.city")} <span className="required">*</span>
                    </label>
                    <input type="text" name="city" className="form-input form-wide" id="register-city"
                           onChange={handleChange}
                           value={values.city}
                           autoComplete="off"
                           placeholder={t("label.city")}
                           required/>
                    {errors.city && (
                        <span className="invalid-feedback relative-error">
                            {t("message.please_provide_a_valid_city")}.
                        </span>
                    )}
                </div>
                <div className="position-relative">
                    <label htmlFor="register-phone">
                        {t("label.phone")} <span className="required">*</span>
                    </label>
                    <input type="tel" name="phone" className="form-input form-wide" id="register-phone"
                           onChange={handleChange}
                           value={values.phone}
                           autoComplete="off"
                           placeholder="+355691234567"
                           pattern="[0-9]{3}[0-9]{2}[0-9]{7}"
                           required />
                    {errors.phone && (
                        <span className="invalid-feedback relative-error">
                            {t("message.please_provide_a_phone_number")}.
                        </span>
                    )}
                </div>
                <div className="position-relative">
                    <label htmlFor="register-email">
                        {t("label.email")} <span className="required">*</span>
                    </label>
                    <input type="text" name="email" className="form-input form-wide" id="register-email"
                           onChange={handleChange}
                           value={values.email}
                           autoComplete="off"
                           placeholder={t("label.email")}
                           required/>
                    {errors.email && (
                        <span className="invalid-feedback relative-error">
                            {t("message.please_provide_an_email")}.
                        </span>
                    )}
                </div>
                <button type="submit" className="btn btn-dark btn-md w-100"
                        onClick={handleSubmit}>
                    {t("label.register")}
                </button>
            </form>
        </>
    )
}

const mapDispatchToProps = {getBusinessRegisterToken}

export default connect( null, mapDispatchToProps )(BusinessRegistrationForm);