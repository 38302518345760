import {GET_CURRENCY} from "../action_types";

const initialState = {
    loading: false,
    list: null,
    error: false,
};

const currency = (state = initialState, action) => {
    switch (action.type) {
        case GET_CURRENCY:
            state = {
                ...state,
                loading: true
            };

            break;
        case GET_CURRENCY.concat("_SUCCESS"):
            let list = action.payload;

            state = {
                ...state,
                list: list,
                loading: false,
            };

            break;
        case GET_CURRENCY.concat("_FAILURE"):
            state = {
                ...state,
                loading: false,
                error: true,
            };

            break;

        default:
            state = {...state};
            break;
    }

    return state;
};

export default currency;