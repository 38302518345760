import {
    HIDE_LOGIN_MODAL,
    HIDE_QUICK, SHOW_LOGIN_MODAL,
    SHOW_QUICK
} from "../action_types";

const initialState = {
    single: null,
    quickShow: false,
    loginShow: false,
};

const modal = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_QUICK:
            state = {
                ...state,
                single: action.payload.product,
                quickShow: true
            };

            break;
        case HIDE_QUICK:
            state = {
                ...state,
                quickShow: false,
                single: null,
            };

            break;
        case SHOW_LOGIN_MODAL:
            state = {
                ...state,
                loginShow: true,
            };

            break;
        case HIDE_LOGIN_MODAL:
            state = {
                ...state,
                loginShow: false,
            };

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default modal;