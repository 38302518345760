import { useLocation } from 'react-router-dom';
import React, {useEffect} from 'react';
import Modal from 'react-modal';
import {connect} from "react-redux";
import ProductMediaOne from "../../partials/product/media/product-media-one";
import ProductDetailOne from "../../partials/product/details/product-detail-one";

// Import Action
import {hideQuickView} from "../../../store/modal/actions";

const customStyles = {
    content: {
        position: 'relative',
        maxWidth: '930px',
        width: '100%',
        padding: '3rem',
        marginLeft: '2rem',
        marginRight: '2rem',
        overflow: 'hidden auto',
        boxShadow: '0 10px 25px rgba(0,0,0,0.5)',
        maxHeight: 'calc( 100vh - 4rem )'
    }
};

function QuickModal ( props ) {
    const { modalShow, product } = props;
    const location = useLocation();

    useEffect( () => {
        closeModal();
    }, [location])

    if ( !product ) {
        return;
    }

    function closeModal () {
        if ( !document.querySelector( '.open-modal' ) ) return;
        document.querySelector( '.open-modal' ).classList.add( "close-modal" );

        props.hideQuickView();
    }

    return (
        <>
            <Modal
                ariaHideApp={false}
                isOpen={ modalShow }
                onRequestClose={ closeModal }
                className="product-single-container product-single-default product-quick-view custom-scrollbar mb-0"
                overlayClassName="ajax-overlay open-modal"
                shouldReturnFocusAfterClose={ false }
                style={ customStyles }
            >
                <div className={ `row skeleton-body skel-shop-products loaded` }>
                    <ProductMediaOne product={ product } parent=".product-quick-view" adClass="col-md-6 mb-md-0" />

                    <div className="col-md-6">
                        <ProductDetailOne product={ product } parent=".product-quick-view" isNav={ false } adClass="mb-0" />
                    </div>
                </div>

                <button title="Close (Esc)" type="button" className="mfp-close" onClick={ closeModal } >×</button>
            </Modal>
        </>
    )
}

const mapStateToProps = ( state ) => {
    return {
        product: state.modal.single,
        modalShow: state.modal.quickShow,
    }
}

const mapDispatchToProps = {
    hideQuickView
}

export default ( connect( mapStateToProps, mapDispatchToProps )( QuickModal ) );
