import {Outlet} from "react-router-dom";

// Public routes
import AboutUs from "../pages/about-us";
import Login from "../pages/login";
import Checkout from "../pages/checkout";
import ContactUs from "../pages/contact-us";
import ForgotPassword from "../pages/forgot-password";
import Shop from "../pages/shop";
import ProductIndex from "../pages/product";
import Home from "../pages/home";
import Blog from "../pages/blog";
import Single from "../pages/blog/details";
import B2B from "../pages/b2b";

const publicRoutes = [
    {path: "/", component: <Home/>, children: []},
    {path: "about-us", component: <AboutUs/>, children: []},
    {
        path: "blog", component: <Blog/>, children: [
            {path: ":id", component: <Single/>}
        ]
    },
    {path: "checkout", component: <Checkout/>, children: []},
    {path: "contact-us", component: <ContactUs/>, children: []},
    {path: "forgot-password", component: <ForgotPassword/>, children: []},
    {path: "login", component: <Login/>, children: []},
    {path: "shop", component: <Shop/>, children: []},
    {
        path: "product", component: <Outlet/>, children: [
            {path: ":id", component: <ProductIndex/>},
        ]
    },
    {path: "b2b", component: <B2B/>, children: []},
];

export {publicRoutes};