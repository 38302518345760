import {GET_BLOG_DETAILS, GET_BLOG_LIST} from "../action_types";

const initialState = {
    loading: false,
    list: null,
    error: false,
    selected_blog: {
        loading: false,
        error: false,
        data: null,
    }
};

const blogs = (state = initialState, action) => {
    let list;
    let selectedBlog;

    switch (action.type) {
        case GET_BLOG_LIST:
            state = {
                ...state,
                loading: true
            };

            break;
        case GET_BLOG_LIST.concat("_SUCCESS"):
            list = state.list ? [...state.list] : [];
            list = [...action.payload];

            state = {
                ...state,
                list: list,
                loading: false,
            };

            break;
        case GET_BLOG_LIST.concat("_FAILURE"):
            state = {
                ...state,
                loading: false,
                error: true,
            };

            break;
        case GET_BLOG_DETAILS:
            selectedBlog = Object.assign({}, state.selected_blog);
            selectedBlog.loading = true;

            state = {
                ...state,
                selected_blog: selectedBlog
            }

            break;
        case GET_BLOG_DETAILS.concat("_SUCCESS"):
            selectedBlog = Object.assign({}, state.selected_blog);
            selectedBlog.loading = false;
            selectedBlog.error = false;
            selectedBlog.data = action.payload;

            state = {
                ...state,
                selected_blog: selectedBlog
            }

            break;
        case GET_BLOG_DETAILS.concat("_FAILURE"):
            selectedBlog = Object.assign({}, state.selected_blog);
            selectedBlog.loading = false;
            selectedBlog.error = true;

            state = {
                ...state,
                selected_blog: selectedBlog
            }

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default blogs;