import React from 'react';
import {useLocation} from 'react-router-dom';

// Import Custom Component
import ALink from "../ALink";

// Import Utils
import {connect} from "react-redux";
import {setCategoryFilter, setProductPage} from "../../../store/products/actions";
import useTranslation from "../../../utils/hooks/useTranslation";
import {mainMenu} from "../../../utils/data/menu";
import {partners} from "../../../utils/data/partners";

function MainMenu({categories, setCategoryFilter, setProductPage}) {
    const {t} = useTranslation();
    const location = useLocation();
    const pathname = location.pathname;

    function handleCategoryChange(categoryId) {
        setCategoryFilter(categoryId);
        setProductPage(1);
    }

    return (
        <>
            <nav className="main-nav w-100">
                <ul className="menu sf-js-enabled sf-arrows">
                    <li className={pathname === '/' ? 'active' : ''}>
                        <ALink href="/">{t("label.home")}</ALink>
                    </li>
                    <li className={pathname === '/about-us' ? 'active' : ''}>
                        <ALink href="/about-us">{t("message.about_us")}</ALink>
                    </li>
                    <li className={pathname.startsWith('/shop') || pathname.startsWith('/product') ? 'active' : ''}>
                        <ALink href="/shop" className="sf-with-ul">{t("label.products")}</ALink>
                        <div className="megamenu megamenu-fixed-width megamenu-3cols">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="submenu">
                                        {
                                            categories?.map((category, index) => (
                                                <li key={"menu-item" + index}>
                                                    <ALink
                                                        href={`/shop?category=${category.id}`} onClick={() => {
                                                        handleCategoryChange(category.id)
                                                    }}>{category.name}</ALink>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <ALink href="#" className="sf-with-ul">{t("label.brands")}</ALink>
                        <ul className="submenu">
                            {
                                partners.list?.map((partner, index) => (
                                    <li key={"menu-item-partner" + index}>
                                        <a target={"_blank"} rel={"noopener noreferrer"}
                                           href={partner.url}>{partner.name}</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </li>
                    <li className={pathname === '/blog' ? 'active' : ''}>
                        <ALink href="/blog">{t("label.blog")}</ALink>
                    </li>
                    <li className={pathname === '/contact-us' ? 'active' : ''}>
                        <ALink href="/contact-us">{t("message.contact_us")}</ALink>
                    </li>
                </ul>
            </nav>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        categories: state.categories.list
    }
}

const mapDispatchToProps = {setCategoryFilter, setProductPage}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);