import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

// Import Custom Component
import SearchInputForm from "../../../forms/searchInputForm";


function SearchForm() {
    const location = useLocation();

    useEffect(() => {
        document.querySelector("body").addEventListener("click", onBodyClick);

        return (() => {
            document.querySelector("body").removeEventListener("click", onBodyClick);
        })
    }, [])

    function onSearchClick(e) {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.parentNode.classList.toggle('show');
    }

    function onBodyClick(e) {
        if (e.target.closest('.header-search')) return e.target.closest('.header-search').classList.contains('show-results') || e.target.closest('.header-search').classList.add('show-results');

        document.querySelector('.header-search.show') && document.querySelector('.header-search.show').classList.remove('show');
        document.querySelector('.header-search.show-results') && document.querySelector('.header-search.show-results').classList.remove('show-results');
    }

    if (location.pathname === "/shop") {
        return <div
            className="header-icon header-search header-search-inline header-search-category w-lg-max text-right mt-0"/>;
    }

    return (
        <div className="header-icon header-search header-search-inline header-search-category w-lg-max text-right mt-0">
            <a href="#" className="search-toggle" role="button" onClick={onSearchClick}><i
                className="icon-search-3"></i></a>
            <SearchInputForm/>
        </div>
    );
}

export default SearchForm;