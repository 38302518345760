import {connect} from 'react-redux';

// Import Custom Component
import ALink from "./ALink";
import CartMenu from "./partials/cart-menu";
import MainMenu from "./partials/main-menu";
import SearchForm from "./partials/search-form";
import LoginModal from "../features/modals/login-modal";
import useTranslation from "../../utils/hooks/useTranslation";
import usePrice from "../../utils/hooks/usePrice";
import {utilityData} from "../../utils/data/utilityData";
import Logo from "../../assets/images/logo.png";
import PhoneIcon from "../../assets/images/phone.png";
import {doLogout} from "../../store/auth/actions";
import React from "react";
import MyAccountLink from "../features/my-account-link";

function Header({adClass = '', user, doLogout}) {
    const {language, setLanguage, t} = useTranslation();
    const {currency, setCurrency} = usePrice();

    function handleLanguageChange(language) {
        setLanguage(language);
        window.location.reload();
    }

    function handleCurrencyChange(currency) {
        setCurrency(currency);
        window.location.reload();
    }

    function getFormattedLanguage(language) {
        switch (language) {
            case "en":
                return "us";
            case "de":
                return "de";
        }
    }

    function openMobileMenu(e) {
        e.preventDefault();
        document.querySelector("body").classList.toggle("mmenu-active");
        e.currentTarget.classList.toggle("active");
    }

    return (
        <header className={`header ${adClass}`}>
            <div className="header-top">
                <div className="container">
                    <div className="header-right header-dropdowns ml-0 ml-sm-auto w-sm-100">
                        <div className="header-dropdown">
                            <ALink href="#"><i
                                className={`flag-${getFormattedLanguage(language)} flag`}></i>{language.toUpperCase()}
                            </ALink>
                            <div className="header-menu">
                                <ul>
                                    {utilityData.languages?.map((language, index) => (
                                        <li key={"language-" + index}
                                            onClick={() => handleLanguageChange(language.symbol)}
                                            className={"cursor-pointer p-2 d-flex align-items-center"}>
                                            <i className={`flag-${getFormattedLanguage(language.symbol)} flag mr-2`}></i>{t(language.label)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {/*<span className="separator"></span>*/}

                        <div className="social-icons">
                            <a href="https://www.facebook.com/NetzwerkDua" target={"_blank"} rel={"noopener noreferrer"}
                               className="social-icon social-facebook icon-facebook"
                               title="Facebook"></a>
                        <a href="https://www.instagram.com/netzwerkdua/" target={"_blank"} rel={"noopener noreferrer"}
                           className="social-icon social-instagram icon-instagram"
                           title="Instagram"></a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-middle sticky-header mobile-sticky">
                <div className="container">
                    <div className="header-left col-lg-2 w-auto pl-0">
                        <button className="mobile-menu-toggler text-primary mr-2" type="button"
                                onClick={openMobileMenu}>
                            <i className="fa fa-bars"></i>
                        </button>

                        <ALink href="/" className="logo">
                            <img src={Logo} alt="Logo"/>
                        </ALink>
                    </div>

                    <div className="header-right w-lg-max justify-content-end">
                        <div className="header-contact d-none d-lg-flex pl-4 mr-0">
                            <img alt="phone" src={PhoneIcon} width="30" height="30" className="pb-1"/>
                            <h6><span>{t("message.call_us_now")}</span><a href="tel:+41798035555"
                                                                          className="text-dark font1">+41 79 803 55
                                55</a></h6>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header-bottom sticky-header desktop-sticky d-none d-lg-block">
                <div className="container">
                    <MainMenu/>
                </div>
            </div>
        </header>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = {doLogout}

export default connect(mapStateToProps, mapDispatchToProps)(Header);