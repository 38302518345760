import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import 'rc-tree/assets/index.css';
import 'react-input-range/lib/css/index.css';
import 'react-image-lightbox/style.css';
import 'react-toastify/dist/ReactToastify.min.css';
import WhatsappIcon from "../assets/images/icons/whatsapp.png";

import StickyNavbar from "./common/partials/sticky-navbar";
import Header from "./common/header";
import Footer from "./common/footer";
import MobileMenu from "./common/partials/mobile-menu";
import QuickModal from "../components/features/modals/quickview";
import VideoModal from "../components/features/modals/video-modal";

import { stickyInit, scrollTopHandlder, scrollTopInit } from "../utils";
import {connect} from "react-redux";
import {hideQuickView} from "../store/modal/actions";

function Layout ({ hideQuickView }) {
    useEffect( () => {
        window.addEventListener( "scroll", stickyInit, { passive: true } );
        window.addEventListener( "scroll", scrollTopInit, { passive: true } );
        window.addEventListener( "resize", stickyInit );
        hideQuickView();

        return () => {
            window.removeEventListener( "scroll", stickyInit, { passive: true } );
            window.removeEventListener( "scroll", scrollTopInit, { passive: true } );
            window.removeEventListener( "resize", stickyInit );
        }
    }, [] )

    return (
        <>
            <div className="page-wrapper">
                <Header />

                <Outlet />

                <Footer />

                <ToastContainer
                    draggable={ false }
                    autoClose={ 3000 }
                    duration={ 300 }
                    className="toast-container"
                    position="bottom-right"
                    closeButton={ false }
                    hideProgressBar={ true }
                    newestOnTop={ true }
                />

                <QuickModal />
                <VideoModal />

            </div>

            <MobileMenu />
            <StickyNavbar />

            <a id="scroll-top" href="#" title="Top" role="button" className="btn-scroll" onClick={ scrollTopHandlder }><i className="icon-angle-up"></i></a>

            <a href="https://wa.me/+41798035555" target={"_blank"} rel={"noopener noreferrer"}>
                <div className="whatsapp-icon">
                    <img alt={"whatsapp-contact"} src={WhatsappIcon} />
                </div>
            </a>
        </>
    )
}

const mapDispatchToProps = {
    hideQuickView,
}

export default connect( null, mapDispatchToProps )(Layout);