import StickyBox from 'react-sticky-box';

// Import Custom Component
import BlogTypeTwo from '../../features/blogs/blog-type-two';
import useTranslation from "../../../utils/hooks/useTranslation";
import {connect} from "react-redux";

function BlogSidebar ({blog}) {
    const { t } = useTranslation();
    const loading = false;
    const {list} = blog;

    function sidebarToggle ( e ) {
        let body = document.querySelector( 'body' );

        e.preventDefault();
        if ( body.classList.contains( 'sidebar-opened' ) ) {
            body.classList.remove( 'sidebar-opened' );
        } else {
            body.classList.add( 'sidebar-opened' );
        }
    }

    function closeSidebar () {
        document.querySelector( 'body' ).classList.contains( 'sidebar-opened' ) && document.querySelector( 'body' ).classList.remove( 'sidebar-opened' );
    }

    return (
        <>
            <div className="sidebar-overlay" onClick={ closeSidebar }></div>
            <div className="sidebar-toggle custom-sidebar-toggle" onClick={ e => sidebarToggle( e ) }><i className="fas fa-sliders-h"></i></div>
            <aside className={ `sidebar mobile-sidebar col-lg-3 h-auto skeleton-body skel-shop-products ${ loading ? '' : 'loaded' }` }>
                <StickyBox className="sidebar-wrapper sticky-sidebar" offsetTop={ 70 }>
                    {
                        loading ?
                            <div className="skel-widget"></div>
                            :
                            <div className="widget widget-post">
                                <h4 className="widget-title">{t("message.recent_posts")}</h4>

                                <ul className="simple-post-list">
                                    {
                                        list?.slice( 0, 2 ).map( ( blog, index ) => (
                                            <BlogTypeTwo blog={ blog } key={ "BlogTypeTwo" + index } />
                                        ) )
                                    }
                                </ul>
                            </div>
                    }
                </StickyBox>
            </aside>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        blog: state.blogs,
    }
}

export default connect( mapStateToProps, null )( BlogSidebar );