import {
    GET_BLOG_DETAILS,
    GET_BLOG_LIST,
    GET_BUSINESS_REGISTRATION_FORM_TOKEN, GET_CART,
    GET_CATEGORY_LIST,
    GET_CONTACT_FORM_TOKEN,
    GET_CURRENCY,
    GET_CURRENT_USER,
    GET_FEATURED_PRODUCT_LIST,
    GET_LATEST_PRODUCT_LIST, GET_PARTNERS_LIST,
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_LIST,
    GET_SIMILAR_PRODUCTS,
    GET_SLIDER_DATA,
    LOGIN, SAVE_CART,
    SEND_BUSINESS_REGISTRATION_FORM,
    SEND_SIMPLE_CONTACT_FORM
} from "../store/action_types";

const endpoints = {
    [LOGIN]: {
        endpoint: state => `/security/login/`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_CURRENT_USER]: {
        endpoint: state => `/user/`,
        method: 'GET',
    },
    [GET_CATEGORY_LIST]: {
        endpoint: state => `/category/`,
        method: 'GET',
    },
    [GET_PRODUCT_LIST]: {
        endpoint: state => `/product/?product_name=&page=${state.products.page}&limit=${state.products.limit}&product_category=${state.products.selected_category}&product_name=${state.products.search}`,
        method: 'GET',
    },
    [GET_FEATURED_PRODUCT_LIST]: {
        endpoint: state => `/product/?featured=1&&limit=100`,
        method: 'GET',
    },
    [GET_SLIDER_DATA]: {
        endpoint: state => `/slider-image/`,
        method: 'GET',
    },
    [GET_LATEST_PRODUCT_LIST]: {
        endpoint: state => `/product/?limit=12`,
        method: 'GET',
    },
    [GET_PRODUCT_DETAILS]: {
        endpoint: (state, action) => `/product/${action.payload}`,
        method: 'GET',
    },
    [GET_CURRENCY]: {
        endpoint: (state, action) => `/currency/`,
        method: 'GET',
    },
    [GET_BLOG_LIST]: {
        endpoint: (state, action) => `/post/`,
        method: 'GET',
    },
    [GET_BLOG_DETAILS]: {
        endpoint: (state, action) => `/post/${action.payload}`,
        method: 'GET',
    },
    [GET_CONTACT_FORM_TOKEN]: {
        endpoint: (state, action) => `/contact/generate-token/contact`,
        method: 'GET',
    },
    [SEND_SIMPLE_CONTACT_FORM]: {
        endpoint: (state, action) => `/contact/email`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_BUSINESS_REGISTRATION_FORM_TOKEN]: {
        endpoint: (state, action) => `/contact/generate-token/b2b_contact`,
        method: 'GET',
    },
    [SEND_BUSINESS_REGISTRATION_FORM]: {
        endpoint: (state, action) => `/contact/b2b`,
        method: 'POST',
        payload: (state, action) => action.payload
    },
    [GET_SIMILAR_PRODUCTS]: {
        endpoint: (state, action) => `/product/${action.payload}/similar`,
        method: 'GET',
    },
    [SAVE_CART]: {
        endpoint: (state, action) => `/cart/`,
        method: 'POST',
        payload: (state, action) => {
            return {cart: JSON.stringify(state.cart.items)}
        }
    },
    [GET_CART]: {
        endpoint: (state, action) => `/cart/`,
        method: 'GET',
    },
    [GET_PARTNERS_LIST]: {
        endpoint: (state, action) => `/partner/`,
        method: 'GET',
    }
};

export default endpoints;