// AUTH
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const GET_CURRENT_USER = 'GET_CURRENT_USER';

// CATEGORY
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';

// PRODUCT
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_FEATURED_PRODUCT_LIST = 'GET_FEATURED_PRODUCT_LIST';
export const GET_LATEST_PRODUCT_LIST = 'GET_LATEST_PRODUCT_LIST';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const SET_PRODUCT_PAGE = 'SET_PRODUCT_PAGE';
export const INCREMENT_PRODUCT_PAGE = 'INCREMENT_PRODUCT_PAGE';
export const DECREMENT_PRODUCT_PAGE = 'DECREMENT_PRODUCT_PAGE';
export const SET_PRODUCT_LIMIT = 'SET_PRODUCT_LIMIT';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
export const SET_PRODUCT_SEARCH_FILTER = 'SET_PRODUCT_SEARCH_FILTER';
export const GET_SIMILAR_PRODUCTS = 'GET_SIMILAR_PRODUCTS';

// BLOG
export const GET_BLOG_LIST = 'GET_BLOG_LIST';
export const GET_BLOG_DETAILS = 'GET_BLOG_DETAILS';

// PARTNERS
export const GET_PARTNERS_LIST = 'GET_PARTNERS_LIST';

// CURRENCY
export const GET_CURRENCY = 'GET_CURRENCY';

// MODAL
export const SHOW_QUICK = 'SHOW_QUICK';
export const HIDE_QUICK = 'HIDE_QUICK';
export const SHOW_LOGIN_MODAL = 'SHOW_LOGIN_MODAL';
export const HIDE_LOGIN_MODAL = 'HIDE_LOGIN_MODAL';

// SLIDER
export const GET_SLIDER_DATA = 'GET_SLIDER_DATA';

// FORMS
export const GET_CONTACT_FORM_TOKEN = 'GET_CONTACT_FORM_TOKEN';
export const CLEAR_CONTACT_FORM_TOKEN = 'CLEAR_CONTACT_FORM_TOKEN';
export const GET_BUSINESS_REGISTRATION_FORM_TOKEN = 'GET_BUSINESS_REGISTRATION_FORM_TOKEN';
export const CLEAR_BUSINESS_REGISTRATION_FORM_TOKEN = 'CLEAR_BUSINESS_REGISTRATION_FORM_TOKEN';
export const SEND_SIMPLE_CONTACT_FORM = 'SEND_SIMPLE_CONTACT_FORM';
export const SEND_BUSINESS_REGISTRATION_FORM = 'SEND_BUSINESS_REGISTRATION_FORM';

// CART
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART';
export const CHANGE_PRODUCT_QUANTITY = 'CHANGE_PRODUCT_QUANTITY';
export const SAVE_CART = 'SAVE_CART';
export const GET_CART = 'GET_CART';