import {GET_BLOG_DETAILS, GET_BLOG_LIST} from "../action_types";

export const getBlogList = () => {
    return {
        type: GET_BLOG_LIST,
        api: true,
    }
};

export const getBlogDetails = (id) => {
    return {
        type: GET_BLOG_DETAILS,
        payload: id,
        api: true,
    }
}