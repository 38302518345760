import React from 'react';
import {Link} from "react-router-dom";

export default function ALink({children, className, style, href, ...props}) {
    return (
        <>
            {href !== '#' ?
                <Link to={href} {...props} scroll={props.scroll?.toString()} className={className} style={style}>
                    {children}
                </Link>
                : <a className={className} href="#" onClick={e => e.preventDefault()}>{children}</a>
            }
        </>
    )
}