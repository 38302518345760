import React from 'react';
import {connect} from "react-redux";
import Modal from 'react-modal';
import {Navigate, useNavigate} from 'react-router-dom';

// Import Custom Component
import {hideLoginModal, showLoginModal} from "../../../store/modal/actions";
import LoginForm from "../../../forms/loginForm";
import useTranslation from "../../../utils/hooks/useTranslation";

const customStyles = {
    content: {
        position: 'relative',
        maxWidth: '525px',
        marginLeft: '1rem',
        marginRight: '1rem',
        outline: 'none',
        backgroundColor: '#fff'
    }
};

function LoginModal({user, open, showLoginModal, hideLoginModal}) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    function closeModal(e) {
        if (!document.querySelector('.open-modal')) return;
        e.preventDefault();
        document.querySelector('.open-modal').classList.add("close-modal");

        if (e.currentTarget.classList.contains('btn-regist')) {
            navigate('/login');
        }

        hideLoginModal();
    }

    function openModal(e) {
        e.preventDefault();
        showLoginModal();
    }

    if(user) {
        return (<Navigate to="/" replace />)
    }

    return (
        <li>
            <a href="#" className="login-link" onClick={openModal} >{t("label.login")}</a>

            {
                open ?
                    <Modal
                        ariaHideApp={false}
                        isOpen={open}
                        style={customStyles}
                        contentLabel="login Modal"
                        className="login-popup"
                        overlayClassName="ajax-overlay open-modal"
                        shouldReturnFocusAfterClose={false}
                        onRequestClose={closeModal}
                    >
                        <div className="modal-wrapper">
                            <div className="container">
                                <h2 className="title">{t("label.login")}</h2>

                                <LoginForm />
                            </div>

                            <button title="Close (Esc)" type="button" className="mfp-close" onClick={closeModal}>×
                            </button>
                        </div>
                    </Modal>
                    : ''
            }
        </li>
    )
}

const mapStateToProps = ( state ) => {
    return {
        user: state.auth.user,
        open: state.modal.loginShow,
    }
}

export default connect(mapStateToProps, {showLoginModal, hideLoginModal})(LoginModal);
