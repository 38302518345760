import React, {useEffect, useState} from 'react';
import {LazyLoadImage} from 'react-lazy-load-image-component';

import {countTo} from '../utils';
import {Link, useLocation} from "react-router-dom";
import {Helmet} from "react-helmet";
import useTranslation from "../utils/hooks/useTranslation";
import AboutImage1 from "../assets/images/about/1.jpg";
import AboutImage3 from "../assets/images/about/3.jpg";
import AboutImage6 from "../assets/images/about/6.jpg";
import AboutImage9 from "../assets/images/about/9.jpg";

export default function AboutUs() {
    const {t} = useTranslation();
    const location = useLocation();
    useEffect(() => {
        countTo();
        let offset = document.querySelector(location.hash ? location.hash : 'body').getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout(() => {
            window.scrollTo({top: offset, behavior: 'smooth'});
        }, 50);
    }, [location])

    const [imagePreviewModalOpened, setImagePreviewModalOpened] = useState(null)

    const renderHeaderMeta = () => {
        return (
            <Helmet>
                <title>Dua Netzwerk - {t("message.about_us")}</title>
            </Helmet>
        )
    }

    return (
        <main className="main about">
            {renderHeaderMeta()}
            <div className="page-header page-header-bg text-left"
                 style={{backgroundColor: "#D4E1EA"}}>
                <div className="container">
                    <h1><span>{t("message.about_us")}</span>
                        {t("message.our_company")}</h1>
                    <Link to="/contact-us" className="btn btn-dark">{t("label.contact")}</Link>
                </div>
            </div>

            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/"><i className="icon-home"></i></Link></li>
                        <li className="breadcrumb-item active" aria-current="page">{t("message.about_us")}</li>
                    </ol>
                </div>
            </nav>

            <div className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="summary" className="container">
                                <p>Netzwerk Dua ist ein führendes Unternehmen im Bereich Sicherheitskamera und
                                    Alarmanlagen mit langjähriger Erfahrung und einer starken Kundenorientierung. Unser
                                    Ziel ist es, unseren Kunden hochwertige Sicherheitslösungen anzubieten, die ihnen
                                    ein Gefühl der Sicherheit und des Schutzes vermitteln.</p>
                                <p>Wir bieten eine breite Palette von Sicherheitskameras und Alarmanlagen an, die sich
                                    durch ihre fortschrittlichen Technologien, ihre Zuverlässigkeit und ihre einfache
                                    Bedienung auszeichnen. Unsere Experten beraten Sie gerne bei der Auswahl des
                                    passenden Systems für Ihre spezifischen Anforderungen und sorgen für eine
                                    professionelle Installation, um eine optimale Leistung und Funktionalität zu
                                    gewährleisten.</p>
                                <p>Bei Netzwerk Dua legen wir großen Wert auf Kundenzufriedenheit. Wir bieten einen
                                    umfassenden Service, der von der individuellen Beratung über die Installation bis
                                    hin zur regelmäßigen Wartung und dem Support reicht. Unser engagiertes Team steht
                                    Ihnen jederzeit zur Verfügung, um Ihre Fragen zu beantworten und eventuelle Probleme
                                    schnell und effizient zu lösen.</p>
                                <p>Vertrauen Sie auf Netzwerk Dua als Ihren Partner für Sicherheitslösungen. Wir sind
                                    bestrebt, Ihnen hochwertige Produkte und einen exzellenten Service zu bieten, damit
                                    Sie sich in Ihrem Zuhause oder Ihrem Unternehmen sicher fühlen können. Kontaktieren
                                    Sie uns noch heute, um mehr über unsere Leistungen zu erfahren und wie wir Ihnen
                                    dabei helfen können, Ihre Sicherheitsziele zu erreichen.``</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="features-section bg-gray">
                <div className="container">
                    <div className="row align-items-start">
                        <div className="col-lg-4">
                            <figure>
                                <div className="lazy-overlay"></div>
                                <LazyLoadImage
                                    alt="product"
                                    src={AboutImage1}
                                    threshold={500}
                                    effect="black and white"
                                    width="100%"
                                    height="100%"
                                />
                            </figure>
                        </div>
                        <div className="col-lg-4">
                            <figure>
                                <div className="lazy-overlay"></div>
                                <LazyLoadImage
                                    alt="product"
                                    src={AboutImage3}
                                    threshold={500}
                                    effect="black and white"
                                    width="100%"
                                    height="100%"
                                />
                            </figure>
                        </div>
                        <div className="col-lg-4">
                            <figure>
                                <div className="lazy-overlay"></div>
                                <LazyLoadImage
                                    alt="product"
                                    src={AboutImage9}
                                    threshold={500}
                                    effect="black and white"
                                    width="100%"
                                    height="100%"
                                />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}