export const utilityData = {
    "currencies": [
        {
            name: "LEK"
        },
        {
            name: "EUR"
        }
    ],
    "languages": [
        {
            symbol: "en",
            label: "label.english"
        },
        {
            symbol: "de",
            label: "label.german"
        }
    ]
}