import {GET_BUSINESS_REGISTRATION_FORM_TOKEN, GET_CONTACT_FORM_TOKEN} from "../action_types";


const initialState = {
    contact_form_token: null,
    loading_cft: false,
    business_register_token: null,
    loading_brt: false,
};

const form = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACT_FORM_TOKEN:
            state = {
                ...state,
                loading_cft: true,
            };

            break;
        case GET_CONTACT_FORM_TOKEN.concat("_SUCCESS"):
            state = {
                ...state,
                contact_form_token: action.payload.token,
                loading_cft: false,
            };

            break;
        case GET_BUSINESS_REGISTRATION_FORM_TOKEN:
            state = {
                ...state,
                loading_brt: true,
            };

            break;
        case GET_BUSINESS_REGISTRATION_FORM_TOKEN.concat("_SUCCESS"):
            state = {
                ...state,
                business_register_token: action.payload.token,
                loading_brt: false,
            };

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default form;