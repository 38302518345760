import React from 'react';
import Reveal from 'react-awesome-reveal';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Cusom Component
import OwlCarousel from '../../features/owl-carousel';

// Import Settigns
import { brandSlider } from '../../../utils/data/slider';
import { fadeIn } from '../../../utils/data/keyframes'
import useTranslation from "../../../utils/hooks/useTranslation";
import {connect} from "react-redux";

function BrandSection ({partners}) {
    const { t } = useTranslation();
    const {list} = partners;

    return (
        <div className="brands-section">
            <h2 className="section-title border-0 ls-0">{t("label.brands")}</h2>
            <Reveal keyframes={ fadeIn } delay={ 100 } duration={ 500 } triggerOnce>
                <OwlCarousel adClass="brands-slider owl-theme nav-outer nav-image-center show-nav-hover" options={ brandSlider }>
                    {list?.map((partner) => (
                        <a href={partner.url} rel={"noopener noreferrer"} target={"_blank"} key={partner.id}>
                            <figure className={"zoom-effect"}>
                                <LazyLoadImage
                                    alt="brand"
                                    src={process.env.REACT_APP_PUBLIC_ASSET_URI + partner.image?.url}
                                    width="100%"
                                    height="56"
                                    threshold={ 500 }
                                    effect="black and white"
                                />
                                <span className={"text-center"}>{partner.name}</span>
                            </figure>
                        </a>
                    ))}
                </OwlCarousel >
            </Reveal>
        </div>
    );
}

const mapStateToProps = ( state ) => {
    return {
        partners: state.partners
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(BrandSection));