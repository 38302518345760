import {combineReducers} from 'redux';
import auth from "./auth/reducers";
import products from "./products/reducers";
import modal from "./modal/reducers";
import categories from "./categories/reducers";
import slider from "./slider/reducers";
import blogs from "./blog/reducers";
import partners from "./partners/reducers";
import currency from "./currency/reducers";
import form from "./forms/reducers";
import cart from "./cart/reducers";

const rootReducer = combineReducers({
    auth,
    products,
    modal,
    categories,
    slider,
    blogs,
    partners,
    currency,
    form,
    cart,
});

export default rootReducer;