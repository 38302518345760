import useTranslation from "../utils/hooks/useTranslation";
import useForm from "../utils/hooks/useForm";
import {connect} from "react-redux";
import {getContactFormToken} from "../store/forms/actions";

function ContactForm({getContactFormToken}) {
    const { t } = useTranslation();

    const contactForm = {
        firstname: '',
        lastname: '',
        company_name: '',
        email: '',
        phone: '',
        message: '',
    }

    const contact = () => {
        if(!hasErrors()) {
            getContactFormToken(values);
        }
    }

    const [values, handleChange, handleSubmit, errors] = useForm(contactForm, contact);

    const hasErrors = () => {
        let hasErrors = false;
        Object.values(values).some((value) => {
            if(!value || value === '') {
                hasErrors = true;
                return true;
            }

            return false;
        });

        return hasErrors;
    }

    return (
        <form className="mb-0" action="#">
            <div className="row">
                <div className="form-group col-sm-6">
                    <label className="mb-1" htmlFor="contact-name">{t("label.name")} <span className="required">*</span></label>
                    <input type="text" className="form-control" id="contact-name" name="firstname" value={values.firstname} onChange={handleChange}
                           required />
                    {errors.firstname && (
                        <div>
                            <span className="invalid-feedback">
                                {t("message.name_field_should_not_be_empty")}!
                            </span>
                            <br/>
                        </div>
                    )}
                </div>
                <div className="form-group col-sm-6">
                    <label className="mb-1" htmlFor="contact-lastname">{t("label.lastname")} <span className="required">*</span></label>
                    <input type="text" className="form-control" id="contact-lastname" name="lastname" value={values.lastname} onChange={handleChange}
                           required />
                    {errors.lastname && (
                        <div>
                            <span className="invalid-feedback">
                                {t("message.lastname_field_should_not_be_empty")}!
                            </span>
                            <br/>
                        </div>
                    )}
                </div>
            </div>

            <div className="form-group">
                <label className="mb-1" htmlFor="contact-name">{t("message.company_name")} <span className="required">*</span></label>
                <input type="text" className="form-control" id="contact-name" name="company_name" value={values.company_name} onChange={handleChange}
                       required />
                {errors.company_name && (
                    <div>
                            <span className="invalid-feedback">
                                {t("message.company_name_field_should_not_be_empty")}!
                            </span>
                        <br/>
                    </div>
                )}
            </div>

            <div className="row">
                <div className="form-group col-sm-6">
                    <label className="mb-1" htmlFor="contact-email">{t("label.email")}	<span className="required">*</span></label>
                    <input type="email" pattern="^[^ ]+@[^ ]+\\.[a-z]{2,6}$" className="form-control" id="contact-email" name="email" value={values.email} onChange={handleChange}
                           required />
                    {errors.email && (
                        <div>
                            <span className="invalid-feedback">
                                {t("message.please_provide_an_email")}!
                            </span>
                            <br/>
                        </div>
                    )}
                </div>
                <div className="form-group col-sm-6">
                    <label className="mb-1" htmlFor="contact-phone">{t("label.phone")}	<span className="required">*</span></label>
                    <input type="tel" pattern="[0-9]{3}[0-9]{2}[0-9]{7}" className="form-control" id="contact-phone" name="phone" value={values.phone} onChange={handleChange}
                           required />
                    {errors.phone && (
                        <div>
                            <span className="invalid-feedback">
                                {t("message.please_provide_a_phone_number")}!
                            </span>
                            <br/>
                        </div>
                    )}
                </div>
            </div>

            <div className="form-group">
                <label className="mb-1" htmlFor="contact-message">{t("message.your_message")} <span className="required">*</span></label>
                <textarea cols="30" rows="1" id="contact-message" className="form-control" value={values.message} onChange={handleChange}
                          name="message" required></textarea>
                {errors.message && (
                    <div>
                            <span className="invalid-feedback">
                                {t("message.message_should_not_be_empty")}!
                            </span>
                        <br/>
                    </div>
                )}
            </div>

            <div className="form-footer mb-0">
                <button type="submit" className="btn btn-dark font-weight-normal" onClick={handleSubmit}>
                    {t("message.send_message")}
                </button>
            </div>
        </form>
    )
}

const mapDispatchToProps = {getContactFormToken}

export default connect( null, mapDispatchToProps )( ContactForm );