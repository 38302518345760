import ALink from "../components/common/ALink";
import useForm from "../utils/hooks/useForm";
import {doLogin} from "../store/auth/actions";
import {connect} from "react-redux";
import useTranslation from "../utils/hooks/useTranslation";

function LoginForm({error, doLogin}) {
    const { t } = useTranslation();

    const loginForm = {
        email: '',
        password: '',
    }

    const login = () => {
        if(!hasErrors()) {
            doLogin(values);
        }
    }

    const [values, handleChange, handleSubmit, errors] = useForm(loginForm, login);

    const hasErrors = () => {
        let hasErrors = false;
        Object.values(values).some((value) => {
            if(!value || value === '') {
                hasErrors = true;
                return true;
            }

            return false;
        });

        return hasErrors;
    }

    return (
        <>
            <form action="#">
                <div>
                    <label htmlFor="login-email">
                        {t("message.email_address")} <span className="required">*</span>
                    </label>
                    <input type="email" name="email" className="form-input form-wide" id="login-email"
                           onChange={handleChange}
                           autoComplete="off"
                           required/>
                    {errors.email && (
                        <div>
                            <span className="invalid-feedback">
                                {t("message.please_provide_an_email")}.
                            </span>
                            <br/>
                        </div>
                    )}
                </div>
                <div>
                    <label htmlFor="login-password">
                        {t("label.password")} <span className="required">*</span>
                    </label>
                    <input type="password" name="password" className="form-input form-wide"
                           onChange={handleChange}
                           id="login-password"
                           autoComplete="off"
                           required/>
                    {errors.password && (
                        <div>
                            <span className="invalid-feedback">
                                {t("message.please_provide_a_password")}.
                            </span>
                            <br/>
                        </div>
                    )}
                    {error && (
                        <div>
                            <span className="invalid-feedback">
                                {t("message.invalid_credentials")}!
                            </span>
                            <br/>
                        </div>
                    )}
                </div>
                <div className="form-footer">
                    <ALink href="/forgot-password"
                           className="forget-password text-dark form-footer-right">{t("message.forgot_password")}?</ALink>
                </div>
                <button type="submit" className="btn btn-dark btn-md w-100"
                        onClick={handleSubmit}>
                    {t("label.login")}
                </button>
            </form>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        error: state.auth.authentication.error,
    }
}

const mapDispatchToProps = {doLogin}

export default connect( mapStateToProps, mapDispatchToProps )(LoginForm);