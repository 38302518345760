import SlideToggle from 'react-slide-toggle';
import {useSearchParams} from 'react-router-dom';
import {useEffect, useMemo, useState} from 'react';
import StickyBox from 'react-sticky-box';
import Tree from 'rc-tree';

import {connect} from "react-redux";
import {getProductList, setCategoryFilter, setProductPage} from "../../../../store/products/actions";
import {updateSearchParams} from "../../../../utils";
import useTranslation from "../../../../utils/hooks/useTranslation";

const TreeNode = ( props ) => {
    return (
        <>
            { props.name }
            { props.count && <span className="products-count">({props.count})</span> }
        </>
    )
}

function ShopSidebarOne ({display, right, category, setCategoryFilter, selectedCategory, setProductPage} ) {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const {list, loading, error} = category;
    const selectedChildrenCategory = getChildrenOfChildrenCategoryParentId(selectedCategory);
    const selectedParentCategory = getChildrenCategoryParentId( selectedChildrenCategory ? selectedChildrenCategory : selectedCategory);
    const [expandedKeys, setExpandedKeys] = useState([]);
    const categories = useMemo( () => {
        let cats = list ? list : [];
        let result = [];
        result = cats.reduce( ( acc, cur ) => {
            let newNode = {
                key: cur.id,
                title: <TreeNode className={"test"} name={ cur.name } count={ cur.children.length ? cur.children.length : null } />,
                children: fixChildrenFormat(cur.children)
            };

            acc.push( newNode );

            return acc;
        }, [] );

        return result;
    }, [ list, fixChildrenFormat ] );

    useEffect( () => {
        return () => {
            closeSidebar();
        }
    }, [] )

    useEffect(() => {
        setExpandedKeys([selectedChildrenCategory, selectedParentCategory, selectedCategory]);
    }, [list, selectedCategory, selectedChildrenCategory, selectedParentCategory]);

    useEffect(() => {
        let treeNodes = document.querySelectorAll('.rc-tree-treenode');
        let isChildren = false;
        treeNodes = [...treeNodes].filter((node, index) => {
            if(node.classList.contains('rc-tree-treenode-switcher-open')) {
                isChildren = true;
            }

            if(isChildren) {
                node.classList.add("border-0");
            }

            if(node.classList.contains('rc-tree-treenode-leaf-last') && index < (treeNodes.length - 1)) {
                isChildren = false;
            }

            return node.classList.contains('rc-tree-treenode-switcher-close')
        })
    }, [expandedKeys])

    function fixChildrenFormat(children) {
        let childNodes = [];
        children?.forEach((child) => {
            childNodes.push({
                key: child.id,
                title: <TreeNode name={ child.name } count={ child.children.length ? child.children.length : null } />,
                children: fixChildrenFormat(child.children)
            });
        })

        return childNodes;
    }

    function filterByCategory ( selected ) {
        let categoryId = selected[0];

        if(!categoryId) {
            return;
        }

        setCategoryFilter(categoryId);

        let params = {
            category: categoryId,
        };

        setProductPage(1);

        params = updateSearchParams(searchParams, params);
        setSearchParams(params);
    }

    function toggleCategory( categoryId ) {
        expandedKeys.includes(categoryId) ? setExpandedKeys(expandedKeys.filter(i => i !== categoryId)) : setExpandedKeys([ ...expandedKeys, categoryId ]);
    }

    function getChildrenCategoryParentId(categoryId) {
        return list?.filter(categories => categories.children.some(cat => cat.id === categoryId))[0]?.id;
    }

    function getChildrenOfChildrenCategoryParentId(categoryId) {
        return list?.flatMap(categories => {
            let res = categories.children.filter(cat => cat.children.some(childCat => childCat.id === categoryId))[0]?.id;
            return res ? res : [];
        })[0];
    }

    function closeSidebar () {
        document.querySelector( 'body' ).classList.contains( 'sidebar-opened' ) && document.querySelector( 'body' ).classList.remove( 'sidebar-opened' );
    }

    if ( error ) {
        return <div>{ error.message }</div>
    }

    return (
        <>
            <div className="sidebar-overlay" onClick={ closeSidebar }></div>
            <aside className={ `sidebar-shop col-lg-3  mobile-sidebar skeleton-body skel-shop-products ${ !loading ? 'loaded' : '' } ${ display === 'none' ? 'd-lg-none' : '' } ${ right ? '' : 'order-lg-first' }` }>
                <StickyBox className="sidebar-wrapper" offsetTop={ 70 }>
                    <div className="widget">
                        <SlideToggle>
                            { ( { setCollapsibleElement} ) => (
                                <>
                                    <h3 className="widget-title">
                                        <a>{t("label.categories")}</a>
                                    </h3>
                                    <div className="overflow-hidden" ref={ setCollapsibleElement }>
                                        <div className="widget-body">
                                            <Tree
                                                className={`no-icon cat-list border-0`}
                                                selectable={ true }
                                                showIcon={ false }
                                                expandedKeys={ expandedKeys }
                                                switcherIcon={ ( props ) => {
                                                    return ( !props.isLeaf ?
                                                        <span className="toggle" onClick={() => toggleCategory(props.eventKey)}></span>
                                                        : ''
                                                    )
                                                } }
                                                selectedKeys={ [ selectedCategory ] }
                                                treeData={ categories }
                                                onSelect={ filterByCategory }
                                            />
                                        </div>
                                    </div>
                                </>
                            ) }
                        </SlideToggle>
                    </div>
                </StickyBox>
            </aside>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        category: state.categories,
        selectedCategory: state.products.selected_category,
    }
}

const mapDispatchToProps = {setCategoryFilter, getProductList, setProductPage}

export default connect( mapStateToProps, mapDispatchToProps )( ShopSidebarOne );