export class ConfigDB {
    static data = {
        api: {
            host: process.env.REACT_APP_API_HOST
        },
        pubnub_publish_key: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
        pubnub_subscribe_key: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
    }
}

export default ConfigDB;