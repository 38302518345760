import {
    CLEAR_BUSINESS_REGISTRATION_FORM_TOKEN,
    CLEAR_CONTACT_FORM_TOKEN,
    GET_BUSINESS_REGISTRATION_FORM_TOKEN,
    GET_CONTACT_FORM_TOKEN, SEND_BUSINESS_REGISTRATION_FORM,
    SEND_SIMPLE_CONTACT_FORM
} from '../action_types';

export const getContactFormToken = (form) => {
    return {
        type: GET_CONTACT_FORM_TOKEN,
        api: true,
        payload: form
    }
};

export const sendSimpleContactForm = (form) => {
    return {
        type: SEND_SIMPLE_CONTACT_FORM,
        api: true,
        payload: form
    }
}

export const clearContactFormToken = () => {
    return {
        type: CLEAR_CONTACT_FORM_TOKEN,
    }
}

export const getBusinessRegisterToken = (form) => {
    return {
        type: GET_BUSINESS_REGISTRATION_FORM_TOKEN,
        api: true,
        payload: form,
    }
};

export const sendB2BContactForm = (form) => {
    return {
        type: SEND_BUSINESS_REGISTRATION_FORM,
        api: true,
        payload: form
    }
}

export const clearBusinessRegistrationFormToken = () => {
    return {
        type: CLEAR_BUSINESS_REGISTRATION_FORM_TOKEN,
    }
}