import {
    DECREMENT_PRODUCT_PAGE,
    GET_FEATURED_PRODUCT_LIST,
    GET_LATEST_PRODUCT_LIST,
    GET_PRODUCT_DETAILS,
    GET_PRODUCT_LIST,
    GET_SIMILAR_PRODUCTS,
    INCREMENT_PRODUCT_PAGE,
    SET_CATEGORY_FILTER,
    SET_PRODUCT_LIMIT,
    SET_PRODUCT_PAGE,
    SET_PRODUCT_SEARCH_FILTER
} from "../action_types";

const initialState = {
    loading: false,
    featured: {
        list: null,
        loading: false,
        error: false,
    },
    latest: {
        list: null,
        loading: false,
        error: false,
    },
    selected_product: {
        loading: false,
        details: null,
        error: false,
    },
    similar_products: {
        list: null,
        loading: false,
        error: false,
    },
    list: null,
    page: 1,
    total_pages: 0,
    limit: 12,
    error: false,
    selected_category: null,
    search: "",
};

const products = (state = initialState, action) => {
    let page;
    let list;
    let featured;
    let latest;
    let selectedProduct;
    let totalPages;
    let similarProducts;

    switch (action.type) {
        case GET_PRODUCT_LIST:
            state = {
                ...state,
                loading: true
            };

            break;
        case GET_PRODUCT_LIST.concat("_SUCCESS"):
            list = list ? [...state.list] : [];
            list = [...action.payload];
            totalPages = action.headers['total-pages'];

            state = {
                ...state,
                list: list,
                loading: false,
                total_pages: totalPages ? totalPages : 0,
            };

            break;
        case GET_PRODUCT_LIST.concat("_FAILURE"):
            state = {
                ...state,
                loading: false,
                error: true,
            };

            break;
        case GET_FEATURED_PRODUCT_LIST:
            featured = Object.assign({}, state.featured);
            featured.loading = true;

            state = {
                ...state,
                featured: featured
            };

            break;
        case GET_FEATURED_PRODUCT_LIST.concat("_SUCCESS"):
            featured = Object.assign({}, state.featured);

            featured.loading = false;
            featured.list = featured.list ? [...state.featured.list] : [];
            featured.list = [...action.payload];

            state = {
                ...state,
                featured: featured,
            };

            break;
        case GET_FEATURED_PRODUCT_LIST.concat("_FAILURE"):
            featured = Object.assign({}, state.featured);
            featured.loading = false;
            featured.error = true;

            state = {
                ...state,
                featured: featured
            };

            break;
        case GET_LATEST_PRODUCT_LIST:
            latest = Object.assign({}, state.latest);
            latest.loading = true;

            state = {
                ...state,
                latest: latest
            };

            break;
        case GET_LATEST_PRODUCT_LIST.concat("_SUCCESS"):
            latest = Object.assign({}, state.latest);
            latest.loading = false;
            latest.list = action.payload;

            state = {
                ...state,
                latest: latest
            };

            break;
        case GET_LATEST_PRODUCT_LIST.concat("_FAILURE"):
            latest = Object.assign({}, state.latest);
            latest.loading = false;
            latest.error = true;

            state = {
                ...state,
                latest: latest
            };

            break;
        case GET_PRODUCT_DETAILS:
            selectedProduct = Object.assign({}, state.selected_product);
            selectedProduct.loading = true;

            state = {
                ...state,
                selected_product: selectedProduct,
            }

            break;
        case GET_PRODUCT_DETAILS.concat("_SUCCESS"):
            selectedProduct = Object.assign({}, state.selected_product);
            selectedProduct.loading = false;
            selectedProduct.details = action.payload;

            state = {
                ...state,
                selected_product: selectedProduct,
            }

            break;
        case GET_PRODUCT_DETAILS.concat("_FAILURE"):
            selectedProduct = Object.assign({}, state.selected_product);
            selectedProduct.loading = false;
            selectedProduct.error = true;

            state = {
                ...state,
                selected_product: selectedProduct,
            }

            break;
        case SET_PRODUCT_PAGE:
            state = {
                ...state,
                page: action.payload
            }

            break;
        case INCREMENT_PRODUCT_PAGE:
            page = state.page;
            page++;

            state = {
                ...state,
                page: page,
            };

            break;
        case DECREMENT_PRODUCT_PAGE:
            page = state.page;
            if(page === 1) {
                return state;
            }

            page--;

            state = {
                ...state,
                page: page,
            };
            break;
        case SET_PRODUCT_LIMIT:
            let limit = action.payload;
            if(limit > 36 || limit < 12) {
                limit = 16;
            }

            state = {
                ...state,
                limit: limit,
            };
            break;
        case SET_CATEGORY_FILTER:

            state = {
                ...state,
                selected_category: action.payload,
                search: ""
            };
            break;
        case SET_PRODUCT_SEARCH_FILTER:

            state = {
                ...state,
                search: action.payload
            };
            break;
        case GET_SIMILAR_PRODUCTS:
            similarProducts = Object.assign({}, state.similar_products);
            similarProducts.loading = true;

            state = {
                ...state,
                similar_products: similarProducts,
            }

            break;
        case GET_SIMILAR_PRODUCTS.concat("_SUCCESS"):
            similarProducts = Object.assign({}, state.similar_products);
            similarProducts.loading = false;
            similarProducts.list = action.payload;

            state = {
                ...state,
                similar_products: similarProducts,
            }

            break;
        case GET_SIMILAR_PRODUCTS.concat("_FAILURE"):
            similarProducts = Object.assign({}, state.similar_products);
            similarProducts.loading = false;
            similarProducts.error = true;

            state = {
                ...state,
                similar_products: similarProducts,
            }

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default products;