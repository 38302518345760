import axios from 'axios';
import endpoints from './api_endpoints';
import ConfigDB from "../config";
import {
    GET_BUSINESS_REGISTRATION_FORM_TOKEN,
    GET_CONTACT_FORM_TOKEN,
    GET_CURRENT_USER,
    LOGIN
} from "../store/action_types";
import {getCurrentUser} from "../store/auth/actions";
import {getFeaturedProductList, getLatestProductList, getProductList} from "../store/products/actions";
import {
    clearBusinessRegistrationFormToken,
    clearContactFormToken,
    sendB2BContactForm,
    sendSimpleContactForm
} from "../store/forms/actions";
import {getCart} from "../store/cart/actions";

const logSuccess = (res, type) => {
    if (process.env.NODE_ENV === "production") {
        return;
    }

    console.log(
        '%cAPI Response', 'background: green; color: black', type, '\n',
        // 'URL:', res.config.url, '\n',
        'Response:', res, '\n',
        'Data:', res.data,
    )
};


const logFailure = (err, type) => {
    if (process.env.NODE_ENV === "production") {
        return;
    }

    if (err.response !== undefined) {
        console.error(
            '%cAPI Error', 'background: red; color: white', type, '\n',
            'Error:', err, '\n',
            // 'URL:', err.config.url, '\n',
            'Response:', err.response,
            'Data:', err.response.data,
        )
    } else {
        console.error(
            '%cAPI Error', 'background: red; color: white', type, '\n',
            'Error:', err, '\n',
            // 'URL:', err.config.url, '\n',
        )
    }
};

const handleAlternativeActions = (dispatch, type, payload, state, previousPayload) => {
    switch (type) {
        case GET_CURRENT_USER.concat("_SUCCESS"):
            dispatch(getFeaturedProductList());
            dispatch(getLatestProductList());
            dispatch(getProductList());
            dispatch(getCart());
            break;
        case GET_CURRENT_USER.concat("_FAILURE"):
            dispatch(getFeaturedProductList());
            dispatch(getLatestProductList());
            dispatch(getProductList());
            break;
        case GET_CONTACT_FORM_TOKEN.concat("_SUCCESS"):
            let form = {...previousPayload, _csrf_token: payload.token};
            dispatch(sendSimpleContactForm(form));
            dispatch(clearContactFormToken());
            break;
        case GET_BUSINESS_REGISTRATION_FORM_TOKEN.concat("_SUCCESS"):
            let businessForm = {...previousPayload, _csrf_token: payload.token};
            dispatch(sendB2BContactForm(businessForm));
            dispatch(clearBusinessRegistrationFormToken());
            break;
        default:
            return;
    }
};

const handleErrorActions = (dispatch, type) => {
};

const handleApiCall = (store, action) => {
    const state = store.getState();
    const {dispatch} = store;
    const instance = axios.create();


    instance.interceptors.response.use(
        response => response,
        (error) => {
            return Promise.reject(error)
        },
    );

    instance.request({
        baseURL: ConfigDB.data.api.host,
        headers: {
            'Content-Type': 'multipart/form-data',
            ...(state.auth.token ? {'authtoken': state.auth.token} : {})
        },
        method: endpoints[action.type].method,
        url: endpoints[action.type].endpoint(state, action),
        data: (
            endpoints[action.type].payload === undefined
                ? undefined
                : endpoints[action.type].payload(state, action)
        ),
    })
        .then((res) => {
            dispatch({
                type: action.type.concat('_SUCCESS'),
                payload: res.data,
                requestAction: action,
                headers: res.headers
            });
            logSuccess(res, action.type);
            handleAlternativeActions(dispatch, action.type.concat('_SUCCESS'), res.data, state, action.payload)
        })
        .catch((err) => {
            dispatch({type: action.type.concat('_FAILURE'), payload: err.response, requestAction: action});

            logFailure(err, action.type);
            handleErrorActions(dispatch, action.type);

            if (err.response !== undefined) {
                // handleErrorMessage(
                //     dispatch,
                //     err.response.data,
                // )
            }
        })
};

const logger = store => next => action => {
    if (action.api === true) handleApiCall(store, action);
    next(action)
}

export default logger;