import {useState} from 'react';

const useForm = (initialForm, submitCallback) => {
    const [state, setState] = useState(initialForm);
    const [error, setError] = useState({});

    const handleSubmit = e => {
        e.preventDefault();
        if(!hasErrors()) {
            e.persist();
            resetForm();
            submitCallback();
        }
    }

    const handleChange = e => {
        e.persist();
        setState(state => ({...state, [e.target.name]: e.target.value}));
        setError(error => ({...error, [e.target.name]: e.target.value === null || e.target.value === ''
            || (e.target.name.includes('email') && !invalidEmail(e.target.value))
            || (e.target.name.includes('phone') && !invalidPhone(e.target.value))
        }));
    }

    const hasErrors = () => {
        let hasErrors = false;
        Object.values(error).some((error) => {
            if(error) {
                hasErrors = true;
                return true;
            }

            return false;
        });

        return hasErrors;
    }

    const resetForm = () => {
        let formValues = {};
        Object.entries(state).forEach(([key]) => {
            formValues[key] = '';
        })

        setState(formValues);
    }

    const invalidPhone = (phone) => {
        const regex = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/;
        return regex.test(phone);
    }

    const invalidEmail = (email) => {
        const regex = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
        return regex.test(email);
    }


    return [state, handleChange, handleSubmit, error];
}

export default useForm;