import GoogleMapReact from 'google-map-react';

import ALink from "../components/common/ALink";
import {useEffect} from "react";
import useTranslation from "../utils/hooks/useTranslation";
import ContactForm from "../forms/contactForm";

export default function ContactUs() {
    const {t} = useTranslation();
    useEffect(() => {
        let offset = document.querySelector('body').getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout(() => {
            window.scrollTo({top: offset, behavior: 'smooth'});
        }, 50);

    }, [])

    const renderMarkers = (map, maps) => {
        return new maps.Marker({
            position: {lat: 47.0739749, lng: 8.2890268},
            map,
            title: 'Rosenaustrasse, 6032 Emmen, Switzerland'
        });
    }

    return (
        <main className="main">
            <nav aria-label="breadcrumb" className="breadcrumb-nav">
                <div className="container">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <ALink href="/"><i className="icon-home"></i></ALink>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {t("message.contact_us")}
                        </li>
                    </ol>
                </div>
            </nav>

            <div className="container contact-us-container">
                <div className="contact-info">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="ls-n-25 m-b-1">
                                {t("message.contact_info")}
                            </h2>
                        </div>

                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="sicon-location-pin"></i>
                                <div className="feature-box-content">
                                    <h3>{t("label.address")}</h3>
                                    <h5>Rosenaustrasse 1, 6032 Emmen – Switzerland</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="fa fa-mobile-alt"></i>
                                <div className="feature-box-content">
                                    <h3>{t("label.phone")}</h3>
                                    <h5>(+41) 79 803 55 55</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="far fa-envelope"></i>
                                <div className="feature-box-content">
                                    <h3>{t("label.email")}</h3>
                                    <h5>info@netzwerkdua.ch</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-3">
                            <div className="feature-box text-center">
                                <i className="far fa-calendar-alt"></i>
                                <div className="feature-box-content">
                                    <h3>{t("message.working_days_hours")}</h3>
                                    <h5>{t("message.open_hours")}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <h2 className="mt-6 mb-2">{t("message.send_us_a_message")}</h2>

                        <ContactForm/>
                    </div>

                    <div className="col-lg-6">
                        <div id="map">
                            <GoogleMapReact
                                layerTypes={[]}
                                bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_API}}
                                defaultCenter={{lat: 47.0739749, lng: 8.2890268}}
                                defaultZoom={16}
                                onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-8"></div>
        </main>
    )
}