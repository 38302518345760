import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from "../../common/ALink";
import useTranslation from "../../../utils/hooks/useTranslation";

export default function BlogTypeTwo ( props ) {
    const { t } = useTranslation();
    const { blog } = props;
    let date = new Date( blog.updated_at );
    let monthArray = [ t("label.jan"), t("label.feb"), t("label.mar"),
        t("label.apr"), t("label.may"), t("label.jun"),
        t("label.jul"), t("label.aug"), t("label.sep"),
        t("label.oct"), t("label.nov"), t("label.dev") ];

    return (
        <li className="media-with-lazy">
            <figure className="post-media">
                <ALink href={ `/blog/${blog.id}` }>
                    <>
                        <div className="lazy-overlay"></div>

                        <LazyLoadImage
                            alt="post_image"
                            src={ process.env.REACT_APP_PUBLIC_ASSET_URI + blog?.image.url }
                            threshold={ 500 }
                            width="100%"
                            height="auto"
                            effect="blur"
                        />
                    </>
                </ALink>
            </figure>

            <div className="post-info">
                <ALink href={ `/blog/${blog.id}` }>{ blog.title }</ALink>
                <div className="post-meta">
                    { ` ${monthArray[ date.getMonth() ]} ${date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()}, ${date.getYear() + 1900}` }
                </div>
            </div>
        </li>
    )
}