import {GET_PARTNERS_LIST} from "../action_types";

const initialState = {
    loading: false,
    list: [],
    error: false,
};

const partners = (state = initialState, action) => {
    var list = [];

    switch (action.type) {
        case GET_PARTNERS_LIST:
            state = {
                ...state,
                list: action.payload,
                loading: true
            };

            break;
        case GET_PARTNERS_LIST.concat("_SUCCESS"):
            list = [...action.payload];

            state = {
                ...state,
                list: list,
                loading: false,
            };

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default partners;