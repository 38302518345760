import {usePubNub } from 'pubnub-react';
import {useEffect} from "react";
import BrowserNotifications from "../utils/browser_notifications";

function PubnubApp({user}) {
    const browserNotifications = BrowserNotifications();
    const pubnub = usePubNub();

    const handleMessage = event => {
        const message = event.message;
        
        switch (message.type) {
            case "order":
                browserNotifications.showNotification("Dua Netzwerk", message.message);
                break;
        }
    };

    useEffect(() => {
        pubnub.addListener({ message: handleMessage });

        pubnub.subscribe({
            channels: [user.pubnub_channel_name]
        });
    }, [pubnub]);
}

export default PubnubApp;