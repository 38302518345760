import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from '../../common/ALink';
import useTranslation from "../../../utils/hooks/useTranslation";

function CartPopup ( props ) {
    const { t } = useTranslation();
    const { product } = props;

    return (
        <div className="minipopup-area">
            <div className="minipopup-box" style={ { top: "0" } }>
                <div className="product media-with-lazy">
                    <figure className="product-media w-100">
                        <ALink href={ `/product/${product.id}` } >
                            <LazyLoadImage
                                alt="product"
                                src={ product.images ? `${process.env.REACT_APP_PUBLIC_ASSET_URI + product.images[ 0 ].url}` : '' }
                                threshold={ 500 }
                                effect="black and white"
                                width="100%"
                                height="auto"
                            />
                        </ALink>
                    </figure>
                    <div className="product-detail">
                        <ALink className="product-name" href={ `/product/${product.id}` }>{ product.name }</ALink>
                        <p>{t("message.has_been_added_to_your_cart")}</p>
                    </div>
                </div>
                <button className="mfp-close"></button>
            </div>
        </div>
    )
}

export default React.memo( CartPopup );