import {GET_CURRENT_USER, LOGIN, LOGOUT} from '../action_types';

export const doLogin = (user) => {
    return {
        type: LOGIN,
        api: true,
        payload: user
    }
};

export const doLogout = () => {
    return {
        type: LOGOUT
    }
};

export const getCurrentUser = () => {
    return {
        type: GET_CURRENT_USER,
        api: true
    }
};