import React from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';

// Import Custom Component
import ALink from '../../components/common/ALink';
import ProductMediaOne from '../../components/partials/product/media/product-media-one';
import ProductDetailOne from '../../components/partials/product/details/product-detail-one';
import RelatedProducts from '../../components/partials/product/widgets/related-products';
import ProductWidgetContainer from '../../components/partials/product/widgets/product-widget-container';
import ProductSidebarOne from '../../components/partials/product/sidebars/sidebar-one';
import useEffectOnce from "../../utils/hooks/useEffectOnce";
import {getProductDetails, getSimilarProducts} from "../../store/products/actions";
import {connect} from "react-redux";
import useTranslation from "../../utils/hooks/useTranslation";

function ProductLeftSidebar ({getProductDetails, getSimilarProducts, product, similarProducts}) {
    const { t } = useTranslation();
    const params = useParams();
    const navigate = useNavigate();
    const productId = params.id;

    useEffectOnce(() => {
        getProductDetails(productId);
        getSimilarProducts(productId);

        let offset = document.querySelector( '.main' ).getBoundingClientRect().top + window.pageYOffset - 58;

        setTimeout( () => {
            window.scrollTo( { top: offset, behavior: 'smooth' } );
        }, 200 );
    })

    function handleProductNameBreadCrumb(name) {
        if(name.length > 30) {
            return name.slice(0, 30) + "...";
        }

        return name;
    }

    const {details, loading, error} = product;
    product = details;

    if ( !productId ) return (
        <Navigate to="/shop" replace/>
    );

    if ( error ) {
        return navigate( '/pages/404' );
    }

    return (
        <main className="main">
            <div className="container">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><ALink href="/"><i className="icon-home"></i></ALink></li>
                        <li className="breadcrumb-item"><ALink href="/shop">{t("label.products")}</ALink></li>
                        <li className="breadcrumb-item active" aria-current="page">{ product && handleProductNameBreadCrumb(product.name) }</li>
                    </ol>
                </nav>

                <div className="row">
                    <ProductSidebarOne />

                    <div className={ `col-lg-9 main-content pb-2 skeleton-body skel-shop-products ${loading ? '' : 'loaded'}` }>
                        <div className="product-single-container product-single-default product-left-sidebar">
                            <div className="row">
                                <ProductMediaOne product={ product } adClass="col-lg-7 col-md-6" />
                                <ProductDetailOne
                                    adClass="col-lg-5 col-md-6"
                                    product={ product }
                                />
                            </div>
                        </div>

                        <RelatedProducts adClass="mb-1" products={similarProducts.list} loading={similarProducts.loading}/>
                    </div>
                </div>

                <hr className="mt-0 mb-0" />
            </div>

            <ProductWidgetContainer />
        </main >
    )
}

const mapStateToProps = ( state ) => {
    return {
        user: state.auth.user,
        product: state.products.selected_product,
        similarProducts: state.products.similar_products,
    }
}

const mapDispatchToProps = {
    getProductDetails,
    getSimilarProducts,
}

export default connect( mapStateToProps, mapDispatchToProps )( ProductLeftSidebar );