import {useEffect, useState} from "react";
import {connect} from "react-redux";
import useTranslation from "../utils/hooks/useTranslation";
import {useNavigate, useLocation} from "react-router-dom";
import {setProductSearchFilter} from "../store/products/actions";

function SearchInputForm({setProductSearchFilter, isMobile}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [searchValue, setSearchValue] = useState("");

    useEffect( () => {
        if(location.pathname === "/shop") return;
        setProductSearchFilter('');
    }, [ location.pathname ] )

    function onSubmitSearchForm ( e ) {
        e.preventDefault();
        setProductSearchFilter(searchValue);
        setSearchValue("");
        navigate("/shop");
    }

    function onSearchChange ( e ) {
        e.preventDefault();
        setSearchValue( e.target.value );
    }

    if(isMobile) {
        return (
            <form action="#" method="get" className="search-wrapper mb-2" onSubmit={ ( e ) => onSubmitSearchForm( e ) }>
                <input type="text" className="form-control mb-0" placeholder={t("label.search") + "..."} value={ searchValue }
                       required onChange={ ( e ) => onSearchChange( e ) } />
                <button className="btn icon-search text-white bg-transparent p-0" type="submit"></button>
            </form>
        )
    }

    return (
        <form action="#" method="get" onSubmit={ ( e ) => onSubmitSearchForm( e ) }>
            <div className="header-search-wrapper">
                <input type="search" className="form-control" name="q" id="q" placeholder={t("label.search") + "..."} value={ searchValue }
                       required onChange={ ( e ) => onSearchChange( e ) } />
                <button className="btn icon-magnifier p-0" title="search" type="submit"></button>
            </div>
        </form>
    )
}

const mapDispatchToProps = {setProductSearchFilter}

export default connect( null, mapDispatchToProps )(SearchInputForm);