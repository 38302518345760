import React from 'react';

// Import Custom Component
import OwlCarousel from '../../features/owl-carousel';

// Import Settigns
import { infoBoxSlider } from '../../../utils/data/slider';
import Icon1 from '../../../assets/images/info/icon1.png';
import Icon2 from '../../../assets/images/info/icon2.png';
import Icon3 from '../../../assets/images/info/icon3.png';

export default function InfoSection () {
    return (
        <OwlCarousel adClass="info-boxes-slider owl-theme mb-2" options={ infoBoxSlider }>
            <div className="info-box info-box-icon-left d-flex flex-column">
                <img src={Icon1} className={"mb-2"} style={{"width": "40px"}}/>
                <div className="info-box-content">
                    <h4>NEW TECHNOLOGY PRODUCT</h4>
                    {/*<p className="text-body">Free shipping on all orders over $99.</p>*/}
                </div>
            </div>

            <div className="info-box info-box-icon-left d-flex flex-column">
                <img src={Icon2} className={"mb-2"} style={{"width": "40px"}}/>

                <div className="info-box-content">
                    <h4>TOP DEALS AND FEATURED OFFERS ON ELECTRONICS</h4>
                    {/*<p className="text-body">100% money back guarantee</p>*/}
                </div>
            </div>

            <div className="info-box info-box-icon-left d-flex flex-column">
                <img src={Icon3} className={"mb-2"} style={{"width": "40px"}}/>

                <div className="info-box-content">
                    <h4>ONLINE SUPPORT FROM THE TECHNICAL TEAM</h4>
                    {/*<p className="text-body">Lorem ipsum dolor sit amet.</p>*/}
                </div>
            </div>
        </OwlCarousel>
    );
}