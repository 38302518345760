import {
    ADD_PRODUCT_TO_CART, CHANGE_PRODUCT_QUANTITY, GET_CART,
    REMOVE_PRODUCT_FROM_CART, SAVE_CART
} from "../action_types";

export const saveCart = () => {
  return {
      type: SAVE_CART,
      api: true
  }
}

export const getCart = () => {
    return {
        type: GET_CART,
        api: true
    }
}

export const addProductToCart = (product) => {
    return {
        type: ADD_PRODUCT_TO_CART,
        payload: product
    }
};

export const removeProductFromCart = (id) => {
    return {
        type: REMOVE_PRODUCT_FROM_CART,
        payload: id,
    }
}

export const changeProductQuantity = (id, quantity) => {
    return {
        type: CHANGE_PRODUCT_QUANTITY,
        payload: {
            id: id,
            quantity: quantity,
        },
    }
}