import React from 'react';
import Reveal from 'react-awesome-reveal';
import {LazyLoadImage} from 'react-lazy-load-image-component';

// Import Custom Component
import ALink from '../../common/ALink';
import OwlCarousel from '../../features/owl-carousel';

// Import Settings
import {HomeSlider} from '../../../utils/data/slider';

// Import Keyframes
import {fadeInUpShorter} from '../../../utils/data/keyframes'
import {hideQuickView} from "../../../store/modal/actions";
import {connect} from "react-redux";
import {removeXSSAttacks} from "../../../utils";
import useTranslation from "../../../utils/hooks/useTranslation";

function HomeSection({slider}) {
    const { t } = useTranslation();
    const {data} = slider;

    const getSliderVariation = (slide, variation) => {
        switch (variation) {
            case 1:
                return <SliderContentVariationOne slide={slide} key={slide.id} />
            case 2:
                return <SliderContentVariationTwo slide={slide} key={slide.id} />
            }
        }

    const Slider = ({slide, variation}) => {
        return getSliderVariation(slide, variation)
    }

    const SliderContentVariationOne = ({slide}) => {
        return (
            <div className="home-slide home-slide1 banner">
                <figure>
                    <LazyLoadImage
                        alt="slider image"
                        src={ process.env.REACT_APP_PUBLIC_ASSET_URI + slide.url }
                        threshold={ 500 }
                        effect="black and white"
                        width="100%"
                    />
                </figure>
                <div className="container d-flex align-items-center">
                    <div className="banner-layer">
                        <Reveal keyframes={ fadeInUpShorter } delay={ 100 } duration={ 1000 }>
                            <>
                                {slide.sub_title && slide.sub_title !== "." && <h4 className="m-b-1" dangerouslySetInnerHTML={removeXSSAttacks(slide.sub_title)} />}
                                {slide.title && slide.title !== "." && <h3 className="m-b-3" dangerouslySetInnerHTML={removeXSSAttacks(slide.title)} />}
                            </>
                        </Reveal>
                    </div>
                </div>
            </div>
        )
    }

    const SliderContentVariationTwo = ({slide}) => {
        return (
            <div className="home-slide home-slide2 banner banner-md-vw">
                <figure>
                    <LazyLoadImage
                        alt="slider image"
                        src={ process.env.REACT_APP_PUBLIC_ASSET_URI + slide.url }
                        threshold={ 500 }
                        effect="black and white"
                        width="100%"
                        // height={ 499 }
                    />
                </figure>
                <div className="container d-flex align-items-center">
                    <div className="banner-layer d-flex justify-content-center">
                        <Reveal keyframes={ fadeInUpShorter } delay={ 100 } duration={ 1000 }>
                            <div className="mx-auto">
                                {slide.sub_title && slide.sub_title !== "." && <h4 className="m-b-1" dangerouslySetInnerHTML={removeXSSAttacks(slide.sub_title)} />}
                                {slide.title && slide.title !== "." && <h3 className="m-b-2" dangerouslySetInnerHTML={removeXSSAttacks(slide.title)} />}
                            </div>
                        </Reveal>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <OwlCarousel adClass="home-slider owl-theme slide-animate show-nav-hover nav-big mb-2 text-uppercase" options={HomeSlider} >
            {data?.map((slide, index) => <Slider slide={slide} variation={index % 2 === 0 ? 1 : 2 } key={slide.id}/>)}
        </OwlCarousel>
    );
}

const mapStateToProps = ( state ) => {
    return {
        slider: state.slider,
    }
}

const mapDispatchToProps = {
    hideQuickView
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(HomeSection));