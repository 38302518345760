import cookie from 'react-cookies'
import {toast} from "react-toastify";
import {GET_CURRENT_USER, LOGIN, LOGOUT} from "../action_types";

function getTokenCookie() {
    return cookie.load("AUTH_TOKEN");
}

function setTokenCookie(token) {
    cookie.save("AUTH_TOKEN", token, {
        "path": "/",
        "sameSite": "lax",
    });
}

const initialState = {
    authentication: {
        loading: false,
        error: false,
    },
    token: getTokenCookie(),
    user: null
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            state = {
                ...state,
                authentication: {
                    ...state.authentication,
                    loading: true
                }
            };

            break;
        case LOGIN.concat("_SUCCESS"):
            setTokenCookie(action.payload.token);
            window.location.reload();

            state = {
                ...state,
                authentication: {
                    ...state.authentication,
                    loading: false,
                },
                token: action.payload.token
            };

            break;
        case LOGIN.concat("_FAILURE"):
            toast.error(action.payload.data.error);

            state = {
                ...state,
                authentication: {
                    ...state.authentication,
                    loading: false,
                    error: true,
                }
            };

            break;
        case GET_CURRENT_USER.concat("_SUCCESS"):
            state = {
                ...state,
                user: action.payload,
            };

            break;
        case LOGOUT:
            setTokenCookie('');
            window.location.reload();

            state = {
                ...state,
                user: null,
                token: null
            };
            break;
        case GET_CURRENT_USER.concat("_FAILURE"):
            setTokenCookie('');

            state = {
                ...state,
                user: null,
                token: null
            };

            break;
        default:
            state = {...state};
            break;
    }

    return state;
};

export default auth;