import React from 'react';
import {connect} from "react-redux";

// Import Custom Component
import useTranslation from "../../utils/hooks/useTranslation";
import ALink from "../common/ALink";

function MyAccountLink({user, token}) {
    const {t} = useTranslation();

    if (!user) {
        return null;
    }

    return (
        <ALink href={"/admin/login?token=" + token} target={"_blank"}>{t("label.my_account")}</ALink>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        token: state.auth.token,
    }
}

export default connect(mapStateToProps)(MyAccountLink);
